import React, { useCallback, useState } from "react";
import {
  Snackbar,
  Alert,
  AlertColor,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { SMSNotificationBarDisplayMode } from "../../../../../enums/SMS/SMSNotificationBarDisplayMode";
import ApproveEventPopup from "../SMSVisualizationEvents/Popups/ApproveEventPopup/ApproveEventPopup";
import useSMSFetchEvents from "../../../../../hooks/sms/useSMSFetchEvents/useSMSFetchEvents";
import GoToVisualizationObjectPopup from "../SMSVisualizationEvents/Popups/GoToVisualizationObjectPopup/GoToVisualizationObjectPopup";
import useSMSGoToVisualizationObject from "../../../../../hooks/sms/useSMSGoToVisualizationObject/useSMSGoToVisualizationObject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  mode: SMSNotificationBarDisplayMode;
  row?: ISMSEvent;
}

const snackbarStyle: SxProps = {
  width: "95%",
  maxWidth: "none",
  opacity: 0.9,
};

const alertStyle: SxProps = {
  width: "100%",
  backgroundColor: "red",
  color: "white",
  display: "flex",
  alignItems: "center",
  borderRadius: "10px",
  fontSize: "1.2rem",
  overflow: "hidden",
  "& .MuiAlert-icon": {
    color: "white",
    fontSize: "2rem",
  },
};

const approveButtonStyle: SxProps = {
  color: "red",
  backgroundColor: "white",
  border: "1px solid white",
  borderRadius: "10px",
  marginLeft: "16px",
  padding: "6px 12px",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
};

const goToButtonStyle: SxProps = {
  color: "white",
  border: "1px solid white",
  borderRadius: "10px",
  marginLeft: "16px",
  padding: "6px 12px",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
};

const expandButtonStyle: SxProps = {
  color: "white",
  marginLeft: "10px",
};

const SMSNotificationBar: React.FC<IProps> = ({
  open,
  message,
  severity,
  mode,
  row,
}) => {
  const { t } = useTranslation();
  const { fetchingStateEvents, fetchAgainEvents } = useSMSFetchEvents();
  const [approveEventPopupParams, setApproveEventPopupParams] = useState<any>({
    isOpen: false,
    row: null,
  });

  const [expanded, setExpanded] = useState(true);

  const {
    isDialogOpen: isVisualizationObjectsToGoDialogOpen,
    visualizationObjectsToGo,
    closeDialog,
    handleEvent,
    goToLocation,
  } = useSMSGoToVisualizationObject();

  const closeApproveEventDialog = useCallback(() => {
    setApproveEventPopupParams({
      isOpen: false,
      row: null,
    });
  }, []);

  const approveEventDialogSuccessCallback = useCallback(() => {
    fetchAgainEvents();
  }, [fetchAgainEvents]);

  const goToVisualizationObjectDialogSuccessCallback = useCallback(
    (objectToGo) => {
      goToLocation(objectToGo);
    },
    [goToLocation]
  );

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={null}
        sx={{
          ...snackbarStyle,
          maxWidth: expanded ? "95%" : "120px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          transition: "max-width 0.3s ease-in-out, opacity 0.3s ease-in-out",
        }}
      >
        <Alert
          severity={severity}
          sx={alertStyle}
          action={
            <div>
              {expanded &&
              mode === SMSNotificationBarDisplayMode.ALARM_EVENT ? (
                <>
                  <Button
                    color="inherit"
                    size="small"
                    sx={approveButtonStyle}
                    onClick={() => {
                      setApproveEventPopupParams({
                        isOpen: true,
                        row: row,
                      });
                    }}
                  >
                    {t("approve")}
                  </Button>
                  <Button
                    color="inherit"
                    size="small"
                    sx={goToButtonStyle}
                    onClick={() => {
                      if (row) {
                        handleEvent(row);
                      }
                    }}
                  >
                    {t("go_to_object")}
                  </Button>
                </>
              ) : null}

              <IconButton
                size="small"
                sx={expandButtonStyle}
                onClick={toggleExpand}
              >
                <FontAwesomeIcon icon={faGripVertical} />
              </IconButton>
            </div>
          }
        >
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {expanded ? message : ""}
          </Box>
        </Alert>
      </Snackbar>

      {!fetchingStateEvents.isFetching && (
        <>
          <ApproveEventPopup
            isOpen={approveEventPopupParams.isOpen}
            row={approveEventPopupParams.row}
            closePopup={closeApproveEventDialog}
            successCallback={approveEventDialogSuccessCallback}
          />

          <GoToVisualizationObjectPopup
            isOpen={isVisualizationObjectsToGoDialogOpen}
            visualizationObjects={visualizationObjectsToGo}
            closePopup={closeDialog}
            successCallback={goToVisualizationObjectDialogSuccessCallback}
          />
        </>
      )}
    </>
  );
};

export default SMSNotificationBar;
