import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import { Link, useLocation, useParams } from "react-router-dom";
import styles from "./buildingsGroup.module.scss";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSMSVisualizationDataContext } from "../../../HelpersFunctions/SMSVisualizationDataProvider";

const BuildingsGroupSMSUser = (props) => {
  const { t } = useTranslation();
  const routeParams: any = useParams();
  const location = useLocation();
  const queryString = location.search;
  const queryParams = new URLSearchParams(queryString);
  const { imagesData, imagesLoading } = useSMSVisualizationDataContext();

  const [imagesUrlsMap, setImagesUrlsMap] = useState<Map<number, string>>(
    new Map()
  );

  const { buildingsData } = useSMSVisualizationDataContext();

  const buildings = useMemo(() => buildingsData || [], [buildingsData]);

  useEffect(() => {
    if (!imagesLoading && imagesData && imagesData.length > 0) {
      let tempMap: Map<number, string> = new Map();

      imagesData?.forEach((item) => {
        tempMap.set(item.id, item.fileUrl);
      });

      setImagesUrlsMap(tempMap);
    }

    return () => {
      imagesUrlsMap.forEach((value) => URL.revokeObjectURL(value));
    };
  }, [imagesData, imagesLoading, imagesUrlsMap]);

  return (
    <div>
      <TopBanner pathName={queryParams.get("menuItemName")} />
      <div className="flexAndCenter">
        <div className={styles["image-grid"]}>
          {buildings
            ?.filter(
              (building) =>
                building.buildingsGroupId === parseInt(routeParams.id)
            )
            ?.map((building) => (
              <div key={building.id} className={styles["image-item"]}>
                <Link
                  to={`/sms-user/buildings/${building.id}?menuItemName=${building.name}`}
                  className={styles["custom-link"]}
                >
                  <figure>
                    <figcaption>{building.name}</figcaption>
                    {building?.imageId ? (
                      <img
                        src={imagesUrlsMap.get(building.imageId)}
                        alt={`${t("building")}: ${building.name}`}
                      />
                    ) : (
                      <img
                        src={"no-image.jpg"}
                        alt={`${t("building")}: ${building.name}`}
                      />
                    )}
                  </figure>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default BuildingsGroupSMSUser;
