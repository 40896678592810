import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";

import useFetchSMSOperationsQueue from "../../../hooks/reactQueryHooks/sms/useFetchSMSOperationsQueue";
import SMSOperationsQueueTable from "../../helpersComponents/SMS/SMSVisualization/Operations/SMSOperationsQueueTable";
import { useTranslation } from "react-i18next";
import SMSOperationsQueueTableSkeleton from "../../helpersComponents/SMS/SMSVisualization/Operations/SMSOperationsQueueTableSkeleton";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import SMSOperationsBackupMenu from "../../helpersComponents/SMS/SMSVisualization/Operations/SMSOperationsBackupMenu";

const OperationsSMSAdministrator = () => {
  const { t } = useTranslation();
  const {
    status,
    refetch,
    data: operationsQueue,
  } = useFetchSMSOperationsQueue();

  const [filterTabValue, setFilterTabValue] = useState<
    "all" | "import" | "export" | "other"
  >("all");

  const filterFunction = (operation: ISMSOperation) => {
    if (filterTabValue === "import")
      return operation.operationType < 100 && operation.operationType > 0;
    if (filterTabValue === "export")
      return operation.operationType < 200 && operation.operationType > 100;
    if (filterTabValue === "other")
      return operation.operationType > 200 && operation.operationType < 300;
    return true;
  };

  return (
    <div>
      <TopBanner pathName={t("operations")} />
      <div className="flexAndCenter">
        <div>
          <Tabs
            value={filterTabValue}
            onChange={(event, newValue) => setFilterTabValue(newValue)}
            sx={{}}
          >
            <Tab
              label={t("all_notification")}
              value={"all"}
              sx={{
                fontWeight: "bold",
                textTransform: "none",
                color: "inherit",
              }}
              disableRipple
            />
            <Tab
              label={t("operation_type_import")}
              value={"import"}
              sx={{ textTransform: "none", color: "inherit" }}
              disableRipple
            />
            <Tab
              label={t("export")}
              value={"export"}
              sx={{ textTransform: "none", color: "inherit" }}
              disableRipple
            />
            <Tab
              label={t("other")}
              value={"other"}
              sx={{ textTransform: "none", color: "inherit" }}
              disableRipple
            />
          </Tabs>
          <SMSOperationsBackupMenu />
        </div>
        {status === "success" && (
          <SMSOperationsQueueTable
            operations={operationsQueue.filter(filterFunction)}
          />
        )}
        {status === "loading" && <SMSOperationsQueueTableSkeleton />}
        {status === "error" && (
          <LoadingWrapper
            isError={true}
            isLodadingProgress={false}
            setIfFetchAgain={() => refetch()}
          >
            <span />
          </LoadingWrapper>
        )}
      </div>
    </div>
  );
};

export default OperationsSMSAdministrator;
