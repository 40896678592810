import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import AddEditImagePopup from "./Popups/AddEditImagePopup";
import { formatSizeInBytes } from "../../../../HelpersFunctions/stringUtils";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import styles from "./imagesLibrary.module.scss";
import useFetchFile from "../../../../hooks/fetchHooks/get/useFetchFile";
import SelectMenu from "../../../helpersComponents/SelectMenu/selectMenu";
import { useSMSVisualizationDataContext } from "../../../../HelpersFunctions/SMSVisualizationDataProvider";
import useSMSOperations from "../../../../hooks/sms/useSMSOperations";
import { SMSOperationType } from "../../../../enums/SMS/SMSOperationType";

const ImagesLibrarySMSAdministrator = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [imageIdToRemove, setImageIdToRemove] = useState(false);
  const { imagesData, imagesLoading, imagesError, imagesRefetch } =
    useSMSVisualizationDataContext();
  const { triggerOperation } = useSMSOperations();

  const [imagesMap, setImagesMap] = useState<Map<number, ISMSCompleteImage>>(
    new Map()
  );

  const columns = [
    {
      name: "file",
      title: t("preview"),
      customFormatter: (row) => {
        if (imagesMap && imagesMap.has(row.id)) {
          return (
            <img
              src={imagesMap.get(row.id)?.fileUrl}
              style={{ maxWidth: "100px" }}
              alt=""
            />
          );
        }

        return null;
      },
    },
    { name: "name", title: t("name") },
    { name: "fileName", title: t("file_name") },
    {
      name: "fileSize",
      title: t("file_size"),
      customFormatter: (row) => formatSizeInBytes(row.fileSize),
    },
    { name: "group", title: t("group") },
    { name: "imageWidth", title: t("width") },
    { name: "imageHeight", title: t("height") },
    {
      name: "isVectorImage",
      title: t("is_vector_image"),
      customFormatter: (row) => (row.isVectorImage ? t("yes") : t("no")),
    },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  useEffect(() => {
    if (!imagesLoading && imagesData && imagesData.length > 0) {
      const tempImagesMap = imagesData.reduce((acc, obj) => {
        acc.set(obj.id, obj);
        return acc;
      }, new Map());

      setImagesMap(tempImagesMap);
    }
  }, [imagesLoading, imagesData]);

  const [, fetchAgainRemoveImage] = useFetchOtherThanGET({
    path: `sms-administrator/images-library/${imageIdToRemove}`,
    method: "DELETE",
    body: imageIdToRemove,
    setBody: setImageIdToRemove,
    successCallback: imagesRefetch,
  });

  useEffect(() => {
    if (imageIdToRemove) {
      fetchAgainRemoveImage();
    }
  }, [imageIdToRemove, fetchAgainRemoveImage]);

  const [imageIdAndFileName, setImageIdAndFileName] = useState<any>([
    false,
    "",
  ]);

  const dataFile = useFetchFile(
    `sms-administrator/download-image`,
    imageIdAndFileName
  );

  useEffect(() => {
    if (dataFile.data === null) return;
    setImageIdAndFileName([false, ""]);
  }, [dataFile]);

  const contentMenuOptions: {
    optionName: React.ReactElement;
    onClick: () => void;
  }[] = [
    {
      optionName: <span>{t("import_from_file")}</span>,
      onClick: () => {
        triggerOperation(SMSOperationType.IMPORT_IMAGES_LIBRARY);
      },
    },
    {
      optionName: <span>{t("export_to_file")}</span>,
      onClick: () => {
        triggerOperation(SMSOperationType.EXPORT_IMAGES_LIBRARY);
      },
    },
    {
      optionName: <span>{t("delete_all_unrelated")}</span>,
      onClick: () => {
        triggerOperation(
          SMSOperationType.DELETE_ALL_UNRELATED_IMAGES_FROM_IMAGES_LIBRARY
        );
      },
    },
  ];

  return (
    <div>
      <TopBanner pathName={`${t("images")}`} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={imagesLoading}
            isError={imagesError}
            setIfFetchAgain={() => {
              imagesRefetch();
            }}
          >
            {!imagesLoading && (
              <TableComponent
                layoutSettingName="layoutSMSAdministratorImagesLibrary"
                rows={imagesData || []}
                columns={columns}
                toolbarComponent={
                  <div className="container">
                    <SelectMenu
                      name={t("content")}
                      variant="outlined"
                      items={contentMenuOptions}
                    />
                  </div>
                }
                actionsColumnUpdated={{
                  popup: (props) => <AddEditImagePopup {...props} />,

                  successCallbackPopup: () => {
                    imagesRefetch();
                  },
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t("confirm_delete_image")}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setImageIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                  customButton1: {
                    ifShow: true,
                    name: t("download"),
                    customComponent: (row) => {
                      return (
                        <>
                          <Button>
                            <FontAwesomeIcon
                              icon={faDownload}
                              size="lg"
                              title={t("download")}
                              className={styles.downloadButton}
                              onClick={() => {
                                setImageIdAndFileName([row.id, row.fileName]);
                              }}
                            />
                          </Button>
                        </>
                      );
                    },
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default ImagesLibrarySMSAdministrator;
