import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Theme,
  useAutocomplete,
} from "@mui/material";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { position: "relative" },
    label: {
      display: "block",
      fontSize: "10px",
      paddingLeft: "5px",
      position: "absolute",
      top: "-12px",
    },
    input: {
      width: 200,
      height: "30px",
      borderRadius: "10px ",
      boxSizing: "border-box",
      padding: "4px 10px",
      border: "1px solid lightgray",
      backgroundColor: "#efefef",
      outline: "2px solid transparent",
      margin: "0 !important",
      transition: "background-color 0.25s ease, outline 0.25s ease",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#fafafa",
        cursor: "pointer",
      },
      "&:focus": {
        border: "1px solid lightgray",
        outline: "2px solid lightblue",
        backgroundColor: "#fafafa",
      },
      "&:disabled": {
        backgroundColor: "#dfdfdf",
        color: "#a0a0a0",
        cursor: "default",
        "&::placeholder": {
          color: "#a0a0a0",
        },
      },
    },
    clearButton: {
      position: "absolute",
      right: "3px",
      top: "2px",
      color: "#bbb",
      padding: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "0px solid transparent",
      height: "25px",
      width: "25px",
      borderRadius: "9999px",
      backgroundColor: "transparent",
      transition: "all 0.1s ease",
      cursor: "pointer",
      fontSize: "20px",
      "&:hover": {
        backgroundColor: "#dddddd99",
        color: "#999",
      },
      "&:active": {
        backgroundColor: "#ddddddff",
        color: "#999",
      },
    },
    listbox: {
      width: 200,
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      zIndex: 9,
      borderRadius: "10px",
      position: "absolute",
      listStyle: "none",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      maxHeight: 200,
      border: "1px solid lightgray",
      transition: "all 0.25s ease",
      "& li": {
        transition: "all 0.25s ease",
        padding: "8px 10px",
        cursor: "pointer",
      },

      "& li:hover": {
        backgroundColor: "#ededed",
      },
      "& li:active": {
        backgroundColor: "lightgray",
      },
    },
  })
);

type ToolbarSelectProps = {
  id?: string;
  value: any;
  options: Array<any>;
  label: string;
  onChange?: (
    event: React.SyntheticEvent,
    value: any,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails
  ) => void;
};
/**
 * ToolbarSelect is a component that provides a dropdown select menu with label and optional clear button.
 * It takes in props:
 * - id: string - used for the id of the input element
 * - options: Array<{ name: string }> - options to be displayed in the dropdown
 * - label: string - label displayed above the input element
 * - value: { name: string } | null - the currently selected value
 * - onChange: (event: React.ChangeEvent<{}>, value: { name: string } | null, reason: string, details: AutocompleteChangeDetails<{ name: string }>) => void - callback function when the selected value is changed
 *
 * @param props - the props object
 * @returns JSX.Element - the rendered component
 */
export default function ToolbarSelect(props: ToolbarSelectProps) {
  const classes = useStyles();
  const isDisabled = props.options.length <= 1;
  const {
    inputValue,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getClearProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: props.id,
    options: props.options,
    getOptionLabel: (option) => option.name,
    onChange: (event, value, reason, details) => {
      props.onChange && props.onChange(event, value, reason, details);
    },
    disabled: isDisabled,
    value: props.value,
  });

  return (
    <div>
      <div className={classes.root} {...getRootProps()}>
        <label className={classes.label} {...getInputLabelProps()}>
          {props.label}
        </label>
        <input
          placeholder={props.label}
          className={classes.input}
          {...getInputProps()}
        />
        {!isDisabled && inputValue.length > 0 && (
          <button className={classes.clearButton} {...getClearProps()}>
            <CloseRoundedIcon fontSize="inherit" />
          </button>
        )}
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option.name}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
