import { useTranslation } from "react-i18next";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { Button, Tooltip } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ApproveRouletteEventPopup from "./Popups/ApproveRouletteEventPopup";
import { TopBanner } from "../TopBanner/topBanner";
import fetchClient from "../../../api/fetchClient";
import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import { ProfileType } from "../../../enums/profileType";

interface IProps {
  employeesToControlApiPath: string;
  rouletteAlertsQueueApiPath: string;
  rouletteAlertsDetailsApiPath: string;
  layoutSettingsName: string;
}

const fetchReportData = async (apiPath): Promise<IRouletteAlertDetails[]> => {
  return fetchClient<IRouletteAlertDetails[]>(apiPath, { useAuth: true });
};

const PersonalControl: React.FC<IProps> = ({
  employeesToControlApiPath,
  rouletteAlertsQueueApiPath,
  rouletteAlertsDetailsApiPath,
  layoutSettingsName,
}) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const { data, isError, isSuccess, isLoading, refetch } = useQuery(
    ["rouletteReport"],
    () => fetchReportData(employeesToControlApiPath),
    {
      enabled: true,
      staleTime: 5000,
      refetchInterval:
        authUser.currentProfile.type === ProfileType.PERSONAL_CONTROL
          ? 30000
          : false,
    }
  );

  const columns = [
    { name: "employee", title: t("employee") },
    { name: "place", title: t("place") },
    { name: "eventTime", title: t("event_time"), type: "date_hours" },
    {
      name: "isEmployeeTestedStr",
      title: t("is_tested"),
    },
    {
      name: "isEmployeeStoppedStr",
      title: t("is_employee_stopped"),
    },
    {
      name: "didEmployeeRefusedTestingStr",
      title: t("did_employee_refused_testing"),
    },
    { name: "note", title: t("note") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "isApprovedStr", title: t("is_approved") },
    { name: "isAlertOmittedStr", title: t("is_omitted") },
    {
      name: "isLockedStr",
      title: t("is_locked"),
    },
  ];

  const processedData = useMemo(() => {
    if (!data) return [];

    const modified = data
      .filter((row) => !row.isEmployeeTested)
      .map((row) => {
        return {
          ...row,
          id: row.alertId,
          employee: `${row.employeeFirstName} ${row.employeeLastName}`,
          isEmployeeTestedStr: row.isEmployeeTested ? t("yes") : t("no"),
          isEmployeeStoppedStr: row.isEmployeeStopped ? t("yes") : t("no"),

          didEmployeeRefusedTestingStr: row.didEmployeeRefusedTesting
            ? t("yes")
            : t("no"),

          isApprovedStr:
            !row.isAlertOmitted && row.isApproved ? t("yes") : t("no"),
          isAlertOmittedStr: row.isAlertOmitted ? t("yes") : t("no"),
          isLockedStr: row.isEmployeeLocked ? t("yes") : t("no"),
        };
      });
    return modified;
  }, [data, t]);

  const [
    approveRouletteEventDialogParams,
    setApproveRouletteEventDialogParams,
  ] = useState<any>({ isOpen: false, rowId: null });

  const closePopup = useCallback(() => {
    setApproveRouletteEventDialogParams({ isOpen: false, rowId: null });
  }, []);

  const successCallback = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      <TopBanner pathName={t("personal_control")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isError={isError}
            isLodadingProgress={isLoading}
            setIfFetchAgain={() => refetch()}
          >
            {isSuccess && (
              <TableComponent
                rows={processedData}
                columns={columns}
                layoutSettingName={layoutSettingsName}
                defaultSorting={[{ columnName: "eventTime", direction: "asc" }]}
                refreshingButton={{
                  ifShow: true,
                  onClick: () => {
                    refetch();
                  },
                  onlyRefreshNow: true,
                }}
                actionsColumnUpdated={{
                  customButton1: {
                    ifShow: true,
                    customComponent: (row) => {
                      return (
                        <>
                          {!row.isApproved && (
                            <Tooltip title={t("approve")}>
                              <Button
                                onClick={() => {
                                  setApproveRouletteEventDialogParams({
                                    isOpen: true,
                                    rowId: row.alertId,
                                  });
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  style={{ fontSize: "20px", color: "green" }}
                                />
                              </Button>
                            </Tooltip>
                          )}
                        </>
                      );
                    },
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>

        <ApproveRouletteEventPopup
          isOpen={approveRouletteEventDialogParams.isOpen}
          rowId={approveRouletteEventDialogParams.rowId}
          successCallback={successCallback}
          closePopup={closePopup}
          rouletteAlertsQueueApiPath={rouletteAlertsQueueApiPath}
          rouletteAlertsDetailsApiPath={rouletteAlertsDetailsApiPath}
        />
      </div>
    </div>
  );
};

export default PersonalControl;
