import { SMSOperationStatus } from "../../../../../enums/SMS/SMSOperationStatus";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getOperationStatusName } from "../../../../../HelpersFunctions/sms/smsOperationsHelpers";

import {
  PendingActions as PendingIcon,
  Autorenew as InProgressIcon,
  RemoveCircle as CanceledIcon,
  Done as CompletedIcon,
  Cancel as ErrorIcon,
} from "@mui/icons-material/";

const chipColorDictionary = {
  [SMSOperationStatus.PENDING]: {
    color: "default",
    icon: <PendingIcon />,
  },
  [SMSOperationStatus.IN_PROGRESS]: {
    color: "primary",
    icon: (
      <InProgressIcon
        sx={{
          animation: "spin 2s linear infinite",
          "@keyframes spin": {
            "0%": {
              transform: "rotate(0deg)",
            },
            "100%": {
              transform: "rotate(360deg)",
            },
          },
        }}
      />
    ),
  },
  [SMSOperationStatus.CANCELED]: {
    color: "warning",
    icon: <CanceledIcon />,
  },
  [SMSOperationStatus.COMPLETED]: { color: "success", icon: <CompletedIcon /> },
  [SMSOperationStatus.ERROR]: { color: "error", icon: <ErrorIcon /> },
};

type StatusChipProps = {
  status: SMSOperationStatus;
};
function StatusChip(props: StatusChipProps) {
  const { t } = useTranslation();
  return (
    <Chip
      label={getOperationStatusName(props.status, t)}
      sx={{ fontSize: "11px" }}
      size="small"
      color={chipColorDictionary[props.status].color as any}
      icon={chipColorDictionary[props.status].icon as any}
    />
  );
}

export default StatusChip;
