import { TFunction } from "react-i18next";
import * as Yup from "yup";
import { PolicyError } from "../../../../enums/policyError";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import { convertPolicyErrorsToStrings } from "../Validation/validationPasswordErrorsConverter";
import { validatePasswordWithPolicy } from "../Validation/validationPasswordWithPolicy";
import formFieldsModel from "./formFieldsModel";

const {
  formFields: {
    //user data
    login,
    permissions,
    leaveCurrentPassword,
    password,
    repeatPassword,
    email,
    alternateEmail1,
    alternateEmail2,
    accessToAllWorkers,
    //password policies
    passwordPoliciesArray,
    passwordPolicy,
    passwordPolicyDateFrom,
    passwordPolicyDateTo,
  },
} = formFieldsModel;

const MAX_DATE_IN_C_SHARP = "9999-12-31";

const validationSchema = (t: TFunction<"translation">) => {
  const validationSchemaObj = Yup.object().shape({
    [login.name]: Yup.string().required(t("field_required")),
    [permissions.name]: Yup.object().nullable().required(t("field_required")),
    [password.name]: Yup.string()
      .when([leaveCurrentPassword.name], {
        is: false,
        then: Yup.string().required(t("field_required")),
      })
      .when([passwordPoliciesArray.name], {
        is: (array: ACUserPolicy[]) =>
          array &&
          array.length > 0 &&
          array[0].policy &&
          array[0].policyDateFrom
            ? true
            : false,
        then: Yup.string().test(
          "validation_password_with_policy",
          "",
          (password, form) => {
            if (password) {
              let errorsStrings = validatePasswordWithCurrentPolicy(
                password,
                form.parent.passwordPoliciesArray,
                t
              );

              if (errorsStrings.length > 0) {
                return form.createError({
                  message: errorsStrings.join("\r\n"),
                });
              }
            }
            return true;
          }
        ),
      }),
    [repeatPassword.name]: Yup.string()
      .when([leaveCurrentPassword.name], {
        is: false,
        then: Yup.string().required(t("field_required")),
      })
      .when([password.name], {
        is: (value: string) => (value && value.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref(password.name)],
          t("passwords_dont_match")
        ),
      }),
    [email.name]: Yup.string().email(t("invalid_email")),
    [alternateEmail1.name]: Yup.string().nullable().email(t("invalid_email")),
    [alternateEmail2.name]: Yup.string().nullable().email(t("invalid_email")),
    [accessToAllWorkers.name]: Yup.boolean(),
    [passwordPoliciesArray.name]: Yup.array().of(
      Yup.object()
        .shape({
          [passwordPolicy.name]: Yup.object()
            .nullable()
            .required(t("field_required")),
          [passwordPolicyDateFrom.name]: Yup.date()
            .nullable()
            .required(t("field_required"))
            .typeError(t("invalid_date")),
          [passwordPolicyDateTo.name]: Yup.date()
            .nullable()
            .typeError(t("invalid_date"))
            .min(
              Yup.ref(passwordPolicyDateFrom.name),
              t("date_to_must_be_later_than_date_from")
            ),
        })
        .test(
          "policies_dates",
          "POLICIES_ERROR_DATES",
          (acUserPolicy: any, form: any) => {
            return validatePoliciesDates(acUserPolicy, form);
          }
        )
    ),
  });

  return validationSchemaObj;
};

export default validationSchema;

const validatePasswordWithCurrentPolicy = (
  password: string,
  passwordPoliciesArray: ACUserPolicy[],
  t: TFunction<"translation">
): string[] => {
  let now = getBeginningOfDate(new Date(), "Day");
  let policyErros: PolicyError[] = [];
  let errorsStrings: string[] = [];
  for (let i = 0; i < passwordPoliciesArray.length; i++) {
    let acUserPolicy = passwordPoliciesArray[i];
    let dateFrom = acUserPolicy.policyDateFrom
      ? getBeginningOfDate(new Date(acUserPolicy.policyDateFrom), "Day")
      : null;
    let dateTo = acUserPolicy.policyDateTo
      ? getBeginningOfDate(new Date(acUserPolicy.policyDateTo), "Day")
      : null;

    if (dateFrom && dateFrom <= now && (!dateTo || (dateTo && dateTo >= now))) {
      let result = validatePasswordWithPolicy(password, acUserPolicy.policy);
      policyErros.push(...result);
      errorsStrings = convertPolicyErrorsToStrings(
        policyErros,
        acUserPolicy.policy,
        t
      );
      break;
    }
  }

  return errorsStrings;
};

const validatePoliciesDates = (acUserPolicy: ACUserPolicy, form: any) => {
  if (acUserPolicy.policy && acUserPolicy.policyDateFrom) {
    const passwordPoliciesACUser = form.parent;
    const currentIndex = form.options.index;
    const dateFrom = acUserPolicy.policyDateFrom
      ? getBeginningOfDate(new Date(acUserPolicy.policyDateFrom), "Day")
      : null;
    const dateTo = acUserPolicy.policyDateTo
      ? getBeginningOfDate(new Date(acUserPolicy.policyDateTo), "Day")
      : getBeginningOfDate(new Date(MAX_DATE_IN_C_SHARP), "Day");

    for (let i = 0; i < passwordPoliciesACUser.length; i++) {
      if (i !== currentIndex) {
        const otherDateFrom = passwordPoliciesACUser[i].policyDateFrom
          ? getBeginningOfDate(
              new Date(passwordPoliciesACUser[i].policyDateFrom),
              "Day"
            )
          : null;

        const otherDateTo = passwordPoliciesACUser[i].policyDateTo
          ? getBeginningOfDate(
              new Date(passwordPoliciesACUser[i].policyDateTo),
              "Day"
            )
          : getBeginningOfDate(new Date(MAX_DATE_IN_C_SHARP), "Day");

        if (otherDateFrom) {
          return otherDateFrom > dateTo || dateFrom! > otherDateTo;
        }
      }
    }
  }

  return true;
};
