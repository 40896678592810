/**
 * Returns a human-readable file size string for a given file size in bytes.
 *
 * @param {number} fileSizeInBytes - The size of the file in bytes.
 * @param {boolean} [si=false] - If true, uses base 1000 units (kB, MB, GB); otherwise uses base 1024 units (KiB, MiB, GiB).
 * @param {number} [dp=1] - The number of decimal places to round to.
 * @return {string} A string with the file size in a human-readable format.
 *
 * @example
 * getReadableFileSizeString(1536) // '1.5 kB'
 * getReadableFileSizeString(1024*1024) // '1.0 MB'
 * getReadableFileSizeString(1024*1024*1024*1024, true, 0) // '10.0 GB'
 */
export default function getReadableFileSizeString(
  fileSizeInBytes: number,
  si = false,
  dp = 1
) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(fileSizeInBytes) < thresh) {
    return fileSizeInBytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    fileSizeInBytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(fileSizeInBytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return fileSizeInBytes.toFixed(dp) + " " + units[u];
}
