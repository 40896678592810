import PersonalControl from "../../helpersComponents/PersonalControl/PersonalControl";

const PersonalControlSuperiorTime = () => {
  return (
    <div>
      <PersonalControl
        employeesToControlApiPath="superior-time/employees-to-control"
        rouletteAlertsQueueApiPath="superior-time/roulette-alerts-queue"
        rouletteAlertsDetailsApiPath="superior-time/roulette-alerts-details"
        layoutSettingsName="layoutSuperiorTimeEmployeesToControl"
      />
    </div>
  );
};

export default PersonalControlSuperiorTime;
