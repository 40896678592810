import React, { useState, useEffect, useCallback } from "react";
import "./hoursRequests.scss";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import { statusDictionary } from "../../../../Constants/statusDictionary";
import { hoursRequestsTypes } from "../../../../Constants/hoursRequestsTypes";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import TableAndActions from "../HolidaysRequests/tableAndActions";
import useFetch from "../../../../hooks/fetchHooks/useFetch/useFetch";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import { useAppSelector } from "../../../../store/hooks";
import { selectTimeWorkersSuperiorObject } from "../../../../reducers/workersLists";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import { useHistory } from "react-router-dom";
import AddEditHoursRequestPopup from "./../../../helpersComponents/Requests/Hours/AddEditHoursRequestPopup";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import RowDetail from "./rowDetail";
import {
  getJoinedRequestsName,
  isJoinedRequestType,
} from "../../../../HelpersFunctions/requests/hoursRequestsHelpers";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";
import moment from "moment";

import WorkerList from "../../../helpersComponents/WorkerList/workerList";

let Cell: any = TableEditColumn.Cell;

const ActionColumn: React.FC = ({
  children,
  row,
  classes,
  ...restProps
}: any) => {
  return (
    <Cell row={restProps.row} className="tableCellEditCancelStyleGlobal">
      {children}
    </Cell>
  );
};

function HoursRequestsContent() {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(
    getBeginningOfDate(new Date(), "Month")
  );
  const [endDate, setEndDate] = useState(getEndOfDate(new Date(), "Month"));
  const [dataModified, setDataModified] = useState<any>([]);
  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);
  const history: any = useHistory();

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(true);

  const layoutSettingName = "layoutSuperiorTimeListsHoursRequestsSelectWorkers";

  let [setIsFetching, fetchingState, fetchAgain] = useFetch({
    method: "GET",
    path:
      "superior-time/hour-requests?DateFrom=" +
      computeDateRangeFetch(startDate, "T00:00:00") +
      "&DateTo=" +
      computeDateRangeFetch(endDate),
  });

  const [requestIdToRemove, setRequestIdToRemove] = useState<false | number>(
    false
  );

  const [deleteRequestFetchingState, deleteRequestFetchAgain] =
    useFetchOtherThanGET({
      path: `superior-time/hour-requests/${requestIdToRemove}`,
      method: "DELETE",
      body: requestIdToRemove,
      setBody: setRequestIdToRemove,
      successCallback: fetchAgain,
    });

  useEffect(() => {
    if (requestIdToRemove !== false) {
      deleteRequestFetchAgain();
    }
  }, [requestIdToRemove, deleteRequestFetchAgain]);

  const [requestsPermissions, requestsPermissionsFetchingState] =
    useFetchAndSetGET<IHolidayRequestsPermissions>({
      path: `superior-time/hour-requests-permissions`,
      startFetchOnInitial: true,
    });

  const timeWorkersSuperiorObject = useAppSelector(
    selectTimeWorkersSuperiorObject
  );

  useEffect(() => {
    if (fetchingState.isError || fetchingState.response === undefined) return;
    if (!Array.isArray(fetchingState.response.resJson)) return;

    let data = fetchingState.response.resJson;
    let datamodifiedLocal: any = [];
    let lpHours = 1;

    data.forEach((element, index) => {
      data[index].organizationalUnits =
        timeWorkersSuperiorObject[element.timeWorkerId]?.organizationalUnits;
      data[index].evidenceNumber =
        timeWorkersSuperiorObject[element.timeWorkerId]?.evidenceNumber;
      data[index].time = convertMinsToHrsMins(
        (new Date(element.dateTo).getTime() -
          new Date(element.dateFrom).getTime()) /
          (1000 * 60)
      );

      if (!isNaN(element.realTime)) {
        data[index].realTime = convertMinsToHrsMins(element.realTime / 60);
      }

      if (isJoinedRequestType(data[index].typeId)) {
        data[index].time2 = convertMinsToHrsMins(
          (new Date(element.date2To).getTime() -
            new Date(element.date2From).getTime()) /
            (1000 * 60)
        );
      }

      data[index].employee = element.firstName + " " + element.lastName;
      data[index].typeIdString = t(hoursRequestsTypes[data[index].typeId]);
      data[index].statusTranslated = t(statusDictionary[data[index].status]);
      data[index].lp = lpHours;
      data[index].isJoinedRequest = isJoinedRequestType(data[index].typeId);
      data[index].joinedRequest1Name = getJoinedRequestsName(
        data[index].typeId,
        t
      )[0];
      data[index].joinedRequest2Name = getJoinedRequestsName(
        data[index].typeId,
        t
      )[1];

      data[index].createdAtString = new Date(element.createdAt).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );

      data[index].dateFromString = new Date(element.dateFrom).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );
      data[index].dateToString = new Date(element.dateTo).toLocaleString(
        t("scheduler_language"),
        {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }
      );

      if (element.date2From && element.date2To) {
        data[index].date2FromString = new Date(
          element.date2From
        ).toLocaleString(t("scheduler_language"), {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        });
        data[index].date2ToString = new Date(element.date2To).toLocaleString(
          t("scheduler_language"),
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }
        );
      }

      data[index].approvedAtString = element?.approvedAt
        ? new Date(element.approvedAt).toLocaleString(t("scheduler_language"), {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "—";

      lpHours++;
      datamodifiedLocal.push(data[index]);
    });

    setDataModified(datamodifiedLocal);
    setIsFetching(false);
  }, [
    fetchingState.isError,
    fetchingState.response,
    timeWorkersSuperiorObject,
    setIsFetching,
    t,
  ]);

  useEffect(() => {
    fetchAgain();
  }, [startDate, endDate, fetchAgain]);

  const columns = [
    { name: "lp", title: "L.p." },
    {
      name: "dateFrom",
      title: t("date_from"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "green" }}>
          {toLocaleStringCustom({
            date: row.dateFrom ? new Date(row.dateFrom) : false,
            t,
            ifShowHours: true,
          })}
        </span>
      ),
    },
    {
      name: "dateTo",
      title: t("date_to2"),
      type: "date_hours",
      customFormatter: (row) => (
        <span style={{ color: "red" }}>
          {toLocaleStringCustom({
            date: row.dateTo ? new Date(row.dateTo) : false,
            t,
            ifShowHours: true,
          })}
        </span>
      ),
    },
    { name: "time", title: t("time") },
    { name: "realExitDate", title: t("real_exit_date"), type: "date_hours" },
    { name: "realEntryDate", title: t("real_entry_date"), type: "date_hours" },
    { name: "realTime", title: t("real_time") },
    { name: "employee", title: t("employee") },
    { name: "evidenceNumber", title: t("evidence_number") },
    { name: "organizationalUnits", title: t("organizational_units") },
    { name: "typeIdString", title: t("type_of_request") },
    { name: "statusTranslated", title: t("status") },
    { name: "approvedBy", title: t("approved_by") },
    { name: "approvedAt", title: t("approved"), type: "date_hours" },
    { name: "comment", title: t("comment") },
    { name: "finallyApprovedBy", title: t("finnal_approved_by") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
  ];

  const commitChanges = ({ added, changed, deleted }) => {
    if (deleted) {
      let row: any = dataModified.find((row) => row.id === deleted[0]);
      setRequestIdToRemove(row.id);
    } else if (added) {
      fetchAgain();
      setSnackbarState([true, "success", t("application_added")]);
    } else if (changed) {
      fetchAgain();
      setSnackbarState([true, "success", t("application_edited")]);
    }
  };

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "dateFrom") {
      return row.dateFrom
        ? moment(row.dateFrom).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    if (columnName === "dateTo") {
      return row.dateTo ? moment(row.dateTo).format("YYYY-MM-DD HH:mm:ss") : "";
    }

    if (columnName === "createdAt") {
      return row.createdAt
        ? moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <TopBanner pathName={t("applications_for_hour")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            pageSize={10}
            allWorkersSelected={allWorkersSelected}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            setDate2={setEndDate}
            darkTheme={true}
          ></DatePickerRange>
        </div>

        {!requestsPermissionsFetchingState.isFetching &&
          !fetchingState.isFetching &&
          !fetchingState.isError && (
            <TableAndActions
              rows={dataModified.filter((element) => {
                if (allWorkersSelected) return true;
                return selectedWorkers.includes(element.timeWorkerId);
              })}
              columns={columns}
              ifAdd={requestsPermissions.canAdd}
              ifEdit={requestsPermissions.canEdit}
              ifDelete={requestsPermissions.canDelete}
              deleteCommandName={t("delete")}
              actionColumn={ActionColumn}
              popup={AddEditHoursRequestPopup}
              openPopup={history.location.state ? true : false}
              parallerPatchPath={"superior-time/hour-requests"}
              ifWhite={true}
              rowDetail={RowDetail}
              fetchObject={{
                fetchAgain,
                isError: fetchingState.isError,
                isFetching:
                  fetchingState.isFetching ||
                  deleteRequestFetchingState.isFetching ||
                  requestsPermissionsFetchingState.isFetching,
                response: fetchingState.response,
                setIsFetching,
              }}
              settingsLayoutName="layoutSuperiorTimeListsHolidaysRequests"
              commitChanges={commitChanges}
              customExcelCellValue={customExportCellValue}
              customPdfCellValue={customExportCellValue}
              dateFrom={startDate}
              dateTo={endDate}
            />
          )}
      </div>

      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default HoursRequestsContent;
