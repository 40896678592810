import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store/hooks";
import { selectSettings } from "../../../reducers/settings";
import Profile from "../../UserPanel/profile";
import { faHome, faInfo } from "@fortawesome/free-solid-svg-icons";
import { ProfileType } from "../../../enums/profileType";
import HomePagePersonalControl from "../../personalControl/Home";

const useNavLinksArray = () => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const navLinksArrayPersonalControl: navLinksArrayTimeWorkerInterface = {
    profileId: ProfileType.PERSONAL_CONTROL,
    routesArrayWithComponents: [
      {
        icon: faInfo,
        name: t("employee_profile"),
        ifHidden: settings.WebUkryjUzytkownikKDZakladkeOMnie?.value === "1",
        singleLink: true,
        path: "/personal-control/profile",
        component: () => <Profile />,
      },
      {
        icon: faHome,
        name: t("personal_control"),
        ifHidden: settings.WebUkryjUzytkownikKDStronaGlowna?.value === "1",
        singleLink: true,
        path: "/personal-control/home",
        component: (props) => <HomePagePersonalControl {...props} />,
      },
    ],
  };

  return navLinksArrayPersonalControl;
};

export default useNavLinksArray;
