import React, { useState, useEffect, useCallback } from "react";
import "./summary.scss";
import { useTranslation } from "react-i18next";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import convertMinsToHrsMins from "../../../../HelpersFunctions/dateAndTime/convertMinsToHrsMins";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import useSummaryRowProps from "./useSummaryRowProps";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";
import { useAppSelector } from "../../../../store/hooks";
import { selectAuthUser } from "../../../../reducers/session";
import { getUniqueItemsByProperties } from "../../../../HelpersFunctions/arraysHelpers";

const SummaryContent = ({ ...props }) => {
  const { t } = useTranslation();
  const authUser = useAppSelector(selectAuthUser);
  const [summaryRowProps, setTotalItems] = useSummaryRowProps();
  let actualDate = new Date();
  const [selectedWorkers, setSelectedWorkers] = useState<any>([]);
  const [startDate, setStartDate] = useState(
    new Date(actualDate.getFullYear(), actualDate.getMonth(), 1, 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(
    new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 0, 23, 59, 59)
  );
  const [ifFetch, setIfFetch] = useState<boolean>(false);
  const [selectedWorkersString, setSelectedWorkersString] = useState<string>();
  const [columns, setColumns] = useState<any>();
  const [rows, setRows] = useState<any>([]);
  const [allWorkers, setAllWorkers] = useState<any>([]);
  const [errorMessagge, setErrorMessagge] = useState<string>("");

  const [allWorkersSelected, setAllWorkersSelected] = useState<boolean>(false);
  const [summariesBody, setSummariesBody] = useState<false | string>(false);

  const [fetchingSummariesState, fetchSummariesAgain] = useFetchOtherThanGET({
    path: "superior-time/summaries",
    method: "POST",
    contentType: "application/json",
    body: summariesBody,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
  });

  const layoutSettingName =
    "layoutSuperiorTimeSummarySummariesOfManyEmployeesEmployees";

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  useEffect(() => {
    if (startDate && endDate) {
      let requestBodyObj = {
        dateFrom: new Date(startDate),
        dateTo: new Date(endDate),
        timeWorkersIds: allWorkersSelected
          ? []
          : selectedWorkersString
          ? selectedWorkersString.split(",").map((e) => parseInt(e))
          : [],
      };

      setSummariesBody(JSON.stringify(requestBodyObj));
    }
  }, [startDate, endDate, allWorkersSelected, selectedWorkersString]);

  useEffect(() => {
    if (startDate && endDate && selectedWorkersString) {
      fetchSummariesAgain();
    }
  }, [startDate, endDate, selectedWorkersString, fetchSummariesAgain]);

  const [columnsWidth, setColumnsWidth] = useState<
    { columnName: string; width: string }[]
  >([]);

  const confirmFilters = () => {
    let selectedWorkersLocal = [...selectedWorkers];
    if (selectedWorkers.length === 0) {
      allWorkers.forEach((worker) => {
        selectedWorkersLocal.push(worker.id);
      });
    }

    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );
    setSelectedWorkersString(selectedWorkersStringLocal);
    updateLastSelectedWorkers();
  };

  useEffect(() => {
    if (fetchingSummariesState.isError) {
      setIfFetch(false);
      setErrorMessagge(t("something_went_wrong"));
      return;
    }
    if (fetchingSummariesState.response?.resJson === null) return;

    const columnsBase = [
      { name: "name", title: t("employee") },
      {
        name: "organizationalUnits",
        title: t("organizational_units"),
        customTooltip: t("organizational_units"),
      },
      {
        name: "evidenceNumber",
        title: t("evidence_number"),
        customTooltip: t("evidence_number"),
      },
      {
        name: "businessEntriesExitsString",
        title: "WS",
        customTooltip: t("business_entries_exits"),
      },
      {
        name: "workTimeStandardString",
        title: "NR",
        customTooltip: t("norm"),
      },
      {
        name: "normativeTimeString",
        title: "CN",
        customTooltip: t("normative_time"),
      },
      { name: "breaksString", title: "PR", customTooltip: t("breaks2") },

      { name: "overtime50String", title: "50", customTooltip: t("overtime50") },
      {
        name: "overtime100String",
        title: "100",
        customTooltip: t("overtime100"),
      },
      {
        name: "overtime150String",
        title: "150",
        customTooltip: t("overtime150"),
      },
      {
        name: "overtime200String",
        title: "200",
        customTooltip: t("overtime200"),
      },
      { name: "nightWorkString", title: "PN", customTooltip: t("night_work") },

      { name: "lateString", title: "SP", customTooltip: t("late") }, //
      { name: "absencesString", title: "N", customTooltip: t("absences") },
      {
        name: "absencesWithWorkedNormString",
        title: "NWN",
        customTooltip: t("absences_with_worked_norm"),
      },
      {
        name: "daysOffWithWorkedNormString",
        title: "DWN",
        customTooltip: t("days_off_wit_worked_norm"),
      },
      {
        name: "homeOfficeString",
        title: "HO",
        customTooltip: t("home_office"),
      },
      {
        name: "normBalanceString",
        title: "SN",
        customTooltip: t("norm_balance"),
      },
      {
        name: "languageLearningHoursString",
        title: "JO",
        customTooltip: t("language_learning_hours"),
      },
      {
        name: "nightWorkOvertimeString",
        title: "PNN",
        customTooltip: t("night_work_overtime"),
      },
      {
        name: "nightWorkScheduleString",
        title: "PNH",
        customTooltip: t("night_work_schedule"),
      },
      { name: "notWorkedString", title: "NDP", customTooltip: t("not_worked") },
      { name: "overtimeString", title: "NG", customTooltip: t("overtime") },
      {
        name: "overtimeBalanceString",
        title: "SNG",
        customTooltip: t("overtime_balance"),
      },
      {
        name: "daysBalanceString",
        title: "SDN",
        customTooltip: t("days_balance"),
      },
    ];
    const columnsLocal = [...columnsBase];
    const rowsLocal: any[] = [];
    let summaryRowPropsExtraColumns: { columnName: string; type: string }[] =
      [];

    /////////////////
    fetchingSummariesState.response?.resJson?.forEach((worker, index) => {
      let singleRow = {};

      worker.absencesDetails.forEach((absences) => {
        const absenceName = absences.absenceTypeName.replace(/\s/g, "-");

        if (!columnsLocal.find((element) => element.name === absenceName)) {
          columnsLocal.push({
            name: absenceName,
            title: absences.absenceTypeNameAbbrev,
            customTooltip: absences.absenceTypeName,
          });

          summaryRowPropsExtraColumns.push({
            columnName: absenceName,
            type: "",
          });
        }

        singleRow[absenceName] =
          absences.sum > 0 ? convertMinsToHrsMins(absences.sum + 1000) : "";
      });

      singleRow["id"] = worker.timeWorkerId;
      singleRow["name"] = worker.firstName + " " + worker.lastName;
      singleRow["organizationalUnits"] = worker.organizationalUnits;
      singleRow["evidenceNumber"] = worker.evidenceNumber;
      singleRow["absencesString"] =
        worker.absences > 0 ? convertMinsToHrsMins(worker.absences) : "";

      singleRow["absencesWithWorkedNormString"] =
        worker.absencesWithWorkedNorm > 0
          ? convertMinsToHrsMins(worker.absencesWithWorkedNorm)
          : "";
      singleRow["breaksString"] =
        worker.breaks > 0 ? convertMinsToHrsMins(worker.breaks) : "";
      singleRow["businessEntriesExitsString"] =
        worker.businessEntriesExits > 0
          ? convertMinsToHrsMins(worker.businessEntriesExits)
          : "";
      singleRow["workTimeStandardString"] =
        worker.workTimeStandard > 0
          ? convertMinsToHrsMins(worker.workTimeStandard)
          : "";
      singleRow["daysOffWithWorkedNormString"] =
        worker.daysOffWithWorkedNorm.toString();
        
      singleRow["homeOfficeString"] =
        worker.homeOffice > 0 ? convertMinsToHrsMins(worker.homeOffice) : "";
      singleRow["languageLearningHoursString"] =
        worker.languageLearningHours > 0
          ? convertMinsToHrsMins(worker.languageLearningHours)
          : "";
      singleRow["lateString"] =
        worker.late > 0 ? convertMinsToHrsMins(worker.late) : "";
      singleRow["nightWorkString"] =
        worker.nightWork > 0 ? convertMinsToHrsMins(worker.nightWork) : "";
      singleRow["nightWorkOvertimeString"] =
        worker.nightWorkOvertime > 0
          ? convertMinsToHrsMins(worker.nightWorkOvertime)
          : "";
      singleRow["nightWorkScheduleString"] =
        worker.nightWorkSchedule > 0
          ? convertMinsToHrsMins(worker.nightWorkSchedule)
          : "";
      singleRow["normBalanceString"] = convertMinsToHrsMins(worker.normBalance);
      singleRow["normativeTimeString"] =
        worker.normativeTime > 0
          ? convertMinsToHrsMins(worker.normativeTime)
          : "";
      singleRow["notWorkedString"] =
        worker.notWorked > 0 ? convertMinsToHrsMins(worker.notWorked) : "";
      singleRow["overtimeString"] =
        worker.overtime > 0 ? convertMinsToHrsMins(worker.overtime) : "";
      singleRow["overtime50String"] =
        worker.overtime50 > 0 ? convertMinsToHrsMins(worker.overtime50) : "";
      singleRow["overtime100String"] =
        worker.overtime100 > 0 ? convertMinsToHrsMins(worker.overtime100) : "";
      singleRow["overtime150String"] =
        worker.overtime150 > 0 ? convertMinsToHrsMins(worker.overtime150) : "";
      singleRow["overtime200String"] =
        worker.overtime200 > 0 ? convertMinsToHrsMins(worker.overtime200) : "";

      singleRow["overtimeBalanceString"] =
        worker.overtimeBalance > 0
          ? convertMinsToHrsMins(worker.overtimeBalance)
          : "";
      singleRow["daysBalanceString"] = worker.daysBalance;

      rowsLocal.push(singleRow);
    });

    let columnsWidthLocal: { columnName: string; width: string }[] = [];
    columnsLocal.forEach((column) => {
      if (column.name === "day") {
        columnsWidthLocal.push({ columnName: column.name, width: "100" });
        return;
      }
      if (column.name === "organizationalUnits") {
        columnsWidthLocal.push({ columnName: column.name, width: "100" });
        return;
      }
      if (column.name === "name" || column.name === "evidenceNumber") {
        return;
      }

      let foundColumn = columnsBase.find((el) => el.name === column.name);
      if (!foundColumn) {
        return;
      }

      columnsWidthLocal.push({ columnName: column.name, width: "70" });
    });
    setTotalItems((prev) => {
      return getUniqueItemsByProperties([
        ...prev,
        ...summaryRowPropsExtraColumns,
      ]);
    });
    setColumns(columnsLocal);
    setColumnsWidth(columnsWidthLocal);
    setRows(rowsLocal);
    setIfFetch(false);
  }, [
    fetchingSummariesState.response,
    fetchingSummariesState.isError,
    setTotalItems,
    t,
  ]);

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {ifFetch ? (
          <CircularProgress size={25} />
        ) : (
          <Button
            onClick={() => {
              confirmFilters();
              setErrorMessagge("");
            }}
          >
            {t("get_employees")}
          </Button>
        )}
      </div>
      <span className="errorMessaggeSummarySuperior">{errorMessagge}</span>
    </>
  );

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    return row[columnName];
  }, []);

  return (
    <>
      <TopBanner pathName={t("summary_many_employees")} />
      <div className="flexAndCenter">
        <div>
          <WorkerList
            layoutSettingName={layoutSettingName}
            setSelectedWorkers={setSelectedWorkers}
            selectedWorkers={selectedWorkers}
            setAllWorkers={setAllWorkers}
            setAllWorkersSelected={setAllWorkersSelected}
          />
        </div>
        <div className="notificationListDate">
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={startDate}
            date2={endDate}
            setDate={setStartDate}
            darkTheme={true}
            setDate2={setEndDate}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>
        <div>
          {!ifFetch && fetchingSummariesState.response?.resJson && (
            <TableComponent
              layoutSettingName="layoutWorkerTimeSummaryKeyPermissionsData"
              title={t("summary_many_employees")}
              rows={rows}
              columns={columns}
              ifEdit={false}
              ifAdd={false}
              ifDelete={false}
              columnsWidth={columnsWidth}
              leftColumns={["name"]}
              summaryRowProps={summaryRowProps}
              cellPadding={"2px;"}
              customExcelCellValue={customExportCellValue}
              customPdfCellValue={customExportCellValue}
              tableExportInfo={{
                dateFrom: startDate,
                dateTo: endDate,
                createdBy: authUser.displayedName,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SummaryContent;
