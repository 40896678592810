import styles from "./MapArrangementToolbar.module.scss";
import { useTranslation } from "react-i18next";
import { selectSettings } from "../../../../../../reducers/settings";
import { useAppSelector } from "../../../../../../store/hooks";
import ButtonStyled from "../../../../Button/button";
import ToolbarSelect from "../ToolbarSelect";
import { useEffect } from "react";

interface IProps {
  buildingsGroups: ISMSBuildingsGroup[];
  buildings: ISMSBuilding[];

  selectedBuildingGroup: ISMSBuildingsGroup | null;
  setSelectedBuildingGroup: React.Dispatch<
    React.SetStateAction<ISMSBuildingsGroup | null>
  >;

  selectedBuilding: ISMSBuilding | null;
  setSelectedBuilding: React.Dispatch<
    React.SetStateAction<ISMSBuilding | null>
  >;

  selectedFloor: ISMSBuildingFloor | null;
  setSelectedFloor: React.Dispatch<
    React.SetStateAction<ISMSBuildingFloor | null>
  >;
  isEditMode: boolean;
  onSaveChangesClick: () => void;
  onCancelChangesClick: () => void;
}

const MapArrangementToolbar: React.FC<IProps> = ({
  buildingsGroups,
  buildings,
  selectedBuildingGroup,
  setSelectedBuildingGroup,
  selectedBuilding,
  setSelectedBuilding,
  selectedFloor,
  setSelectedFloor,
  isEditMode,
  onSaveChangesClick,
  onCancelChangesClick,
}) => {
  const { t } = useTranslation();
  const settings = useAppSelector(selectSettings);

  const btnSaveChangesClick = () => {
    if (onSaveChangesClick) onSaveChangesClick();
  };

  const btnCancelChangesClick = () => {
    if (onCancelChangesClick) onCancelChangesClick();
  };

  useEffect(() => {
    if (buildingsGroups.length === 1) {
      setSelectedBuildingGroup(buildingsGroups[0]);
    }

    const availableBuildings = buildings.filter(
      (b) => b.buildingsGroupId === selectedBuildingGroup?.id
    );

    if (availableBuildings.length === 1) {
      setSelectedBuilding(availableBuildings[0]);
    }
    if (selectedBuilding?.floors.length === 1) {
      setSelectedFloor(selectedBuilding.floors[0]);
    }
  }, [
    buildingsGroups,
    buildings,
    selectedBuildingGroup,
    selectedBuilding,
    setSelectedBuilding,
    setSelectedBuildingGroup,
    setSelectedFloor,
  ]);

  return (
    <div className={styles.mainContainerToolbar}>
      {settings.WebSMSWylaczObslugeGrupyBudynkow?.value !== "1" && (
        <>
          <ToolbarSelect
            value={selectedBuildingGroup}
            options={buildingsGroups || []}
            label={t("buildings_group")}
            onChange={(_, buildingGroup: ISMSBuildingsGroup) => {
              setSelectedBuildingGroup(buildingGroup);
              setSelectedBuilding(null);
              setSelectedFloor(null);
            }}
          />
          <span className={styles.divider}>/</span>
        </>
      )}
      <ToolbarSelect
        value={selectedBuilding}
        options={
          buildings.filter(
            (b) => b.buildingsGroupId === selectedBuildingGroup?.id
          ) || []
        }
        label={t("building")}
        onChange={(_, building: ISMSBuilding) => {
          setSelectedBuilding(building);
          setSelectedFloor(null);
        }}
      />
      <span className={styles.divider}>/</span>
      <ToolbarSelect
        value={selectedFloor}
        options={selectedBuilding?.floors || []}
        label={t("floor")}
        onChange={(_, floor: ISMSBuildingFloor) => {
          setSelectedFloor(floor);
        }}
      />

      {isEditMode && (
        <div className={styles.toolbarButtonsItem}>
          <ButtonStyled color="green" onClick={btnSaveChangesClick}>
            {t("save_changes")}
          </ButtonStyled>
          <ButtonStyled
            color="red"
            variant="outlined"
            onClick={btnCancelChangesClick}
          >
            {t("cancel")}
          </ButtonStyled>
        </div>
      )}
    </div>
  );
};

export default MapArrangementToolbar;
