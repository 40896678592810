import { useRef, useState, useEffect, useCallback } from "react";
import { SMSOperationType } from "../../enums/SMS/SMSOperationType";
import useFetchOtherThanGET from "../fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../useConfirm/useConfirm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const createFormData = (
  file: File,
  operationType: SMSOperationType | null
): FormData => {
  const formData = new FormData();
  formData.append("File", file);
  formData.append("Content", JSON.stringify({ operationType }));
  return formData;
};

const useSMSOperations = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [file, setFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState<SMSOperationType | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { confirm } = useConfirm();

  const [exportOperationBodyRequest, setExportOperationBodyRequest] =
    useState<any>(false);

  const successCallback = useCallback(async () => {
    let result = await confirm({
      typeDialog: "info",
      text: t("operation_added_to_queue_successfully"),
      buttons: [{ buttonName: t("ok"), buttonId: 0 }],
    });

    if (result === 0) {
      history.replace("/sms-administrator/operations");
    }
  }, [confirm, history, t]);

  const [fetchingStatePostAddOperation, fetchAgainPostAddOperation] =
    useFetchOtherThanGET({
      path: "sms-administrator/operations-queue",
      method: "POST",
      body: exportOperationBodyRequest,
      setBody: setExportOperationBodyRequest,
      disableErrorSnackbar: false,
      disableSuccessSnackbar: true,
      successCallback,
    });

  useEffect(() => {
    if (exportOperationBodyRequest) {
      fetchAgainPostAddOperation();
    }
  }, [exportOperationBodyRequest, fetchAgainPostAddOperation]);

  useEffect(() => {
    if (isUploading && file && fileType) {
      setExportOperationBodyRequest(createFormData(file, fileType));
    } else {
      setExportOperationBodyRequest(null);
    }
  }, [isUploading, file, fileType]);

  useEffect(() => {
    if (!inputRef.current) {
      const input = document.createElement("input");
      input.type = "file";
      input.style.display = "none";

      input.addEventListener("change", (event) => handleFileChange(event));
      document.body.appendChild(input);

      inputRef.current = input;
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("change", (event) =>
          handleFileChange(event)
        );
        document.body.removeChild(inputRef.current);
        inputRef.current = null;
      }
    };
  }, []);

  const handleFileChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const file = target.files ? target.files[0] : null;
    if (file) {
      setFile(file);
      setFileType((prevType) => prevType);
      setIsUploading(true);
      target.value = "";
    }
  };

  const triggerOperation = useCallback((type: SMSOperationType) => {
    switch (type) {
      case SMSOperationType.IMPORT_BACKUP:
      case SMSOperationType.IMPORT_BUILDINGS_GROUPS:
      case SMSOperationType.IMPORT_BUILDINGS:
      case SMSOperationType.IMPORT_OBJECTS:
      case SMSOperationType.IMPORT_IMAGES_LIBRARY:
      case SMSOperationType.IMPORT_OBJECTS_GROUPS_CATEGORIES_LIBRARY:
      case SMSOperationType.IMPORT_OBJECTS_GROUPS_LIBRARY:
      case SMSOperationType.IMPORT_OBJECTS_STATES_GROUPS_LIBRARY:
        setFileType(type);
        if (inputRef.current) {
          inputRef.current.click();
        }
        break;

      case SMSOperationType.EXPORT_BACKUP:
      case SMSOperationType.EXPORT_BUILDINGS_GROUPS:
      case SMSOperationType.EXPORT_BUILDINGS:
      case SMSOperationType.EXPORT_OBJECTS:
      case SMSOperationType.EXPORT_IMAGES_LIBRARY:
      case SMSOperationType.EXPORT_OBJECTS_GROUPS_CATEGORIES_LIBRARY:
      case SMSOperationType.EXPORT_OBJECTS_GROUPS_LIBRARY:
      case SMSOperationType.EXPORT_OBJECTS_STATES_GROUPS_LIBRARY:
      case SMSOperationType.CLEAR_VISUALIZATION_DATA:
      case SMSOperationType.IMPORT_OBJECTS_FROM_AC:
      case SMSOperationType.DELETE_ALL_UNRELATED_IMAGES_FROM_IMAGES_LIBRARY:
        let formData = new FormData();
        formData.append("Content", JSON.stringify({ operationType: type }));
        setExportOperationBodyRequest(formData);
        break;
    }

    /*setFileType(type);
    if (inputRef.current) {
      inputRef.current.click();
    }*/
  }, []);

  useEffect(() => {
    if (
      fetchingStatePostAddOperation.response?.status === 200 ||
      fetchingStatePostAddOperation.isError
    ) {
      setFile(null);
      setIsUploading(false);
    }
  }, [
    fetchingStatePostAddOperation.response?.status,
    fetchingStatePostAddOperation.isError,
  ]);

  return {
    triggerOperation,
  };
};

export default useSMSOperations;
