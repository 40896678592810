import PersonalControl from "../../helpersComponents/PersonalControl/PersonalControl";

const PersonalControlSuperiorAc = () => {
  return (
    <div>
      <PersonalControl
        employeesToControlApiPath="superior-ac/employees-to-control"
        rouletteAlertsQueueApiPath="superior-ac/roulette-alerts-queue"
        rouletteAlertsDetailsApiPath="superior-ac/roulette-alerts-details"
        layoutSettingsName="layoutSuperiorAcEmployeesToControl"
      />
    </div>
  );
};

export default PersonalControlSuperiorAc;
