import { TFunction } from "react-i18next";
import { SMSOperationType } from "../../enums/SMS/SMSOperationType";
import { SMSOperationStatus } from "../../enums/SMS/SMSOperationStatus";

export function getOperationTypeName(
  operationType: SMSOperationType,
  t: TFunction<"translation">
): string {
  switch (operationType) {
    case SMSOperationType.IMPORT_BACKUP:
      return t("operation_type_import_backup");
    case SMSOperationType.IMPORT_BUILDINGS_GROUPS:
      return t("operation_type_import_buildings_groups");
    case SMSOperationType.IMPORT_BUILDINGS:
      return t("operation_type_import_buildings");
    case SMSOperationType.IMPORT_OBJECTS:
      return t("operation_type_import_objects");
    case SMSOperationType.IMPORT_IMAGES_LIBRARY:
      return t("operation_type_import_images_library");
    case SMSOperationType.IMPORT_OBJECTS_GROUPS_CATEGORIES_LIBRARY:
      return t("operation_type_import_objects_groups_categories");
    case SMSOperationType.IMPORT_OBJECTS_GROUPS_LIBRARY:
      return t("operation_type_import_objects_groups");
    case SMSOperationType.IMPORT_OBJECTS_STATES_GROUPS_LIBRARY:
      return t("operation_type_import_objects_states_groups");
    case SMSOperationType.EXPORT_BACKUP:
      return t("operation_type_export_backup");
    case SMSOperationType.EXPORT_BUILDINGS_GROUPS:
      return t("operation_type_export_buildings_groups");
    case SMSOperationType.EXPORT_BUILDINGS:
      return t("operation_type_export_buildings");
    case SMSOperationType.EXPORT_OBJECTS:
      return t("operation_type_export_objects");
    case SMSOperationType.EXPORT_IMAGES_LIBRARY:
      return t("operation_type_export_images_library");
    case SMSOperationType.EXPORT_OBJECTS_GROUPS_CATEGORIES_LIBRARY:
      return t("operation_type_export_objects_groups_categories");
    case SMSOperationType.EXPORT_OBJECTS_GROUPS_LIBRARY:
      return t("operation_type_export_objects_groups");
    case SMSOperationType.EXPORT_OBJECTS_STATES_GROUPS_LIBRARY:
      return t("operation_type_export_objects_states_groups");
    case SMSOperationType.IMPORT_OBJECTS_FROM_AC:
      return t("operation_type_import_objects_from_ac");
    case SMSOperationType.DELETE_ALL_UNRELATED_IMAGES_FROM_IMAGES_LIBRARY:
      return t(
        "operation_type_delete_all_unrelated_images_from_images_library"
      );

    default:
      return t("unknown_operation_type");
  }
}

export function getOperationStatusName(
  operationStatus: SMSOperationStatus,
  t: TFunction<"translation">
): string {
  switch (operationStatus) {
    case SMSOperationStatus.PENDING:
      return t("operation_status_pending");

    case SMSOperationStatus.IN_PROGRESS:
      return t("operation_status_in_progress");

    case SMSOperationStatus.CANCELED:
      return t("operation_status_canceled");

    case SMSOperationStatus.COMPLETED:
      return t("operation_status_completed");

    case SMSOperationStatus.ERROR:
      return t("operation_status_error");

    default:
      return t("unknown_operation_status");
  }
}
