import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import useSMSOperations from "../../../../../hooks/sms/useSMSOperations";
import { useTranslation } from "react-i18next";
import { ListItemText, Tooltip } from "@mui/material";
import { UploadFile as ImportIcon } from "@mui/icons-material/";
import { SaveAs as ExportIcon } from "@mui/icons-material/";
import { SMSOperationType } from "../../../../../enums/SMS/SMSOperationType";

const SMSOperationsBackupMenu = () => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (operationType: SMSOperationType) => {
    triggerOperation(operationType);
    setAnchorEl(null);
  };

  const { triggerOperation } = useSMSOperations();
  return (
    <>
      <Tooltip title={t("actions")} placement="top">
        <IconButton
          onClick={handleClick}
          sx={{
            padding: 0,
            minWidth: "40px",
            height: "40px",
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              borderRadius: "10px",
              overflow: "visible",
              filter: "drop-shadow(0px 0px 3px rgba(0,0,0,0.15))",
              mt: 0.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "inherit",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => handleClose(SMSOperationType.EXPORT_BACKUP)}
          sx={{ color: "#444", height: "35px" }}
        >
          <ExportIcon sx={{ paddingRight: "10px" }} />
          <ListItemText> {t("operation_type_export_backup")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose(SMSOperationType.IMPORT_BACKUP)}
          sx={{ color: "red", height: "35px" }}
        >
          <ImportIcon sx={{ paddingRight: "10px" }} />
          <ListItemText>{t("operation_type_import_backup")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SMSOperationsBackupMenu;
