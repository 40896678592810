import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { AutocompleteFormik, TextFieldFormik } from "../FormikInputs";
import GetCardTagPopupRow from "../GetCardTagPopupRow/getCardTagPopupRow";
import LoadingWrapper from "../LoadingWrapper/loadingWrapper";
import { Row, RowInput, RowTitle } from "../PopupHelpers";

interface Props {
  onSubmit: (values: Partial<AddReplacementCard>) => void;
  isOpen: boolean;
  pathWorkersApi: string;
}

const PopupContentReplacementCard: React.FC<Props> = ({
  onSubmit,
  isOpen,
  pathWorkersApi,
}) => {
  const { t } = useTranslation();

  const initialValues: Partial<AddReplacementCard> = {
    acWorker: null,
    newCardUniversalNumber: "",
    location: "",
    cardDescription: "",
  };

  const [acWorkers, fetchingStateACWorkers, fetchAgainACWorkers] =
    useFetchAndSetGET<ACWorker[]>({
      path: pathWorkersApi,
      startFetchOnInitial: true,
    });

  return (
    <>
      <LoadingWrapper
        isLodadingProgress={fetchingStateACWorkers.isFetching}
        isError={fetchingStateACWorkers.isError}
        setIfFetchAgain={() => {
          if (fetchingStateACWorkers.isError) {
            fetchAgainACWorkers();
          }
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          validationSchema={Yup.object({
            acWorker: Yup.object().nullable().required(t("field_required")),
            newCardUniversalNumber: Yup.string().required(t("field_required")),
          })}
        >
          {(formikProps) => {
            return (
              <Form noValidate id="submitForm">
                <Row>
                  <RowTitle width={150}>{t("worker")}:</RowTitle>
                  <RowInput width={350}>
                    <AutocompleteFormik
                      options={acWorkers.filter((worker) => worker.isActive)}
                      getOptionLabel={(option: ACWorker) => {
                        return `${option.firstName} ${option.lastName} - ${option.evidenceNumber}`;
                      }}
                      width="100%"
                      getOptionSelected={(
                        option: ACWorker,
                        value: ACWorker
                      ) => {
                        return option.id === value.id;
                      }}
                      label={t("worker")}
                      name="acWorker"
                      required={true}
                    />
                  </RowInput>
                </Row>

                <Row>
                  <GetCardTagPopupRow
                    setFieldValue={formikProps.setFieldValue}
                    name="newCardUniversalNumber"
                    componentId={`replacementCards/newCardUniversalNumber`}
                    isPopupOpen={isOpen}
                    mask={"****************"}
                    widthTitle={150}
                    widthInput={350}
                  />
                </Row>

                <Row>
                  <RowTitle width={150}>{t("location")}:</RowTitle>
                  <RowInput width={350}>
                    <TextFieldFormik
                      ifToUpperCaseShipsAndPorts={true}
                      label={t("location")}
                      name="location"
                      type="text"
                    />
                  </RowInput>
                </Row>

                <Row>
                  <RowTitle width={150}>{t("card_description")}:</RowTitle>
                  <RowInput width={350}>
                    <TextFieldFormik
                      ifToUpperCaseShipsAndPorts={true}
                      label={t("card_description")}
                      name="cardDescription"
                      type="text"
                      multiline={true}
                      rows={3}
                    />
                  </RowInput>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </LoadingWrapper>
    </>
  );
};

export default PopupContentReplacementCard;
