import PersonalControl from "../../helpersComponents/PersonalControl/PersonalControl";

const HomePagePersonalControl = () => {
  return (
    <div>
      <PersonalControl
        employeesToControlApiPath="personal-control/employees-to-control"
        rouletteAlertsQueueApiPath="personal-control/roulette-alerts-queue"
        rouletteAlertsDetailsApiPath="personal-control/roulette-alerts-details"
        layoutSettingsName="layoutPersonalControlEmployeesToControl"
      />
    </div>
  );
};

export default HomePagePersonalControl;
