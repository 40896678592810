//TODO: NEEDS IMPROVEMENTS
import { useQuery } from "@tanstack/react-query";
import { useAppSelector } from "../../store/hooks";
import { selectAuthUser, selectLanguage } from "../../reducers/session";
import { ProfileType } from "../../enums/profileType";
import { getMimeType } from "../../HelpersFunctions/mimeTypes";

const fetchImagesLibraryFromAPI = async (
  url: string,
  lang: string,
  token: string
): Promise<ISMSImagesLibraryItem[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: "bearer " + token,
      "Accept-Language": lang,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const fetchImageContentFromAPI = async (
  url: string,
  lang: string,
  token: string
): Promise<ArrayBuffer> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: "bearer " + token,
      "Accept-Language": lang,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.arrayBuffer();
};

const useSMSImagesLibrary = () => {
  const authUser = useAppSelector(selectAuthUser);
  const language = useAppSelector(selectLanguage);

  const profileName =
    authUser.currentProfile?.type === ProfileType.SMS_ADMINISTRATOR
      ? "sms-administrator"
      : "sms-user";

  const downloadImagesLibraryUrl =
    window.globalConfig.API_URL + "/api/" + profileName + "/images-library";
  const downloadImageBaseUrl =
    window.globalConfig.API_URL + "/api/" + profileName + "/download-image";

  const imagesLibraryQueryFunc = async () => {
    const data = await fetchImagesLibraryFromAPI(
      downloadImagesLibraryUrl,
      language,
      authUser.token
    );
    const completeImageData: Promise<ISMSCompleteImage>[] = data.map(
      async (image) => {
        const imgUrl = downloadImageBaseUrl + `/${image.id}`;
        const imageContent = await fetchImageContentFromAPI(
          imgUrl,
          language,
          authUser.token
        );

        return {
          ...image,
          fileContent: imageContent,
          fileUrl: URL.createObjectURL(
            new Blob([imageContent], {
              type: getMimeType(image.fileName),
            })
          ),
        };
      }
    );
    return Promise.all(completeImageData);
  };

  return useQuery<ISMSCompleteImage[], Error>(
    ["smsImagesLibrary"],
    imagesLibraryQueryFunc,
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: Infinity,
      keepPreviousData: true,

      enabled:
        authUser.currentProfile?.type === ProfileType.SMS_ADMINISTRATOR ||
        authUser.currentProfile?.type === ProfileType.SMS_USER,
    }
  );
};

export default useSMSImagesLibrary;
