import { useTranslation } from "react-i18next";
import {
  RowDetailsContainer,
  RowDetailsRow,
  RowDetailsTitle,
  RowDetailsTitleValue,
  RowDetailsValue,
} from "../../../helpersComponents/RowDetailsHelpers";
import toLocaleStringCustom from "../../../../HelpersFunctions/dateAndTime/toLocaleStringCustom";

const RowDetailsNotificationsDailyLimit = ({ row }) => {
  const { t } = useTranslation();
  return (
    <div>
      <RowDetailsContainer>
        <RowDetailsRow>
          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("day")}:</RowDetailsTitle>
            <RowDetailsValue>
              {toLocaleStringCustom({ date: row?.day, t })}
            </RowDetailsValue>
          </RowDetailsTitleValue>

          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("limit")}:</RowDetailsTitle>
            <RowDetailsValue>{row.limit}</RowDetailsValue>
          </RowDetailsTitleValue>

          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("created")}:</RowDetailsTitle>
            <RowDetailsValue>{row.createdBy}</RowDetailsValue>
          </RowDetailsTitleValue>

          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("data_created")}:</RowDetailsTitle>
            <RowDetailsValue>
              {toLocaleStringCustom({
                date: new Date(row.createdAt),
                t,
                ifShowHours: true,
              })}
            </RowDetailsValue>
          </RowDetailsTitleValue>

          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("edited")}:</RowDetailsTitle>
            <RowDetailsValue>
              {row.editedBy ? row.editedBy : "-"}
            </RowDetailsValue>
          </RowDetailsTitleValue>

          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("data_edited")}:</RowDetailsTitle>
            <RowDetailsValue>
              {row.editedAt
                ? toLocaleStringCustom({
                    date: new Date(row.editedAt),
                    t,
                    ifShowHours: true,
                  })
                : "-"}
            </RowDetailsValue>
          </RowDetailsTitleValue>

          <RowDetailsTitleValue>
            <RowDetailsTitle>{t("reason")}</RowDetailsTitle>
            <RowDetailsValue>{row.reasonString}</RowDetailsValue>
          </RowDetailsTitleValue>
        </RowDetailsRow>
      </RowDetailsContainer>
    </div>
  );
};

export default RowDetailsNotificationsDailyLimit;
