import React, { useCallback, useRef, useState } from "react";
import styles from "./workerList.module.scss";
import TableComponent from "../TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect } from "react";
import {
  selectTimeWorkersSuperiorArray,
  selectTimeWorkersSuperiorObject,
} from "../../../reducers/workersLists";
import { ClickAwayListener } from "@material-ui/core";
import ButtonStyled from "../Button/button";
import { selectSettings } from "../../../reducers/settings";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";
import WorkerSearchBar from "./workerSearchBar";
import WorkerPresetSelection from "./workerPresetSelection";

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    padding: 0,
    display: "block",
    borderTop: "1px solid #e7eaec",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
interface WorkerListInterface {
  layoutSettingName: string;
  setSelectedWorkers: (selection: (string | number)[]) => void;
  selectedWorkers: number[];
  setAllWorkers?: React.Dispatch<{ [key in string]: any }[]>;
  pageSize?: number;
  allWorkersSelected?: boolean;
  setAllWorkersSelected?: (status: boolean) => void;
  autoCollapse?: boolean;
}

let WorkerList = React.memo(function WorkerListMemo({
  setAllWorkers,
  selectedWorkers,
  layoutSettingName,
  setSelectedWorkers,
  pageSize = 5,
  allWorkersSelected,
  setAllWorkersSelected,
  autoCollapse = true,
}: WorkerListInterface) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isSearchbarFocused, setIsSearchBarFocused] = useState<boolean>(false);
  const authUser = useAppSelector(selectAuthUser);

  const columns = [
    { name: "firstName", title: t("first_name") },
    { name: "lastName", title: t("last_name") },
    { name: "position", title: t("position") },
    { name: "organizationalUnits", title: t("departments") },
    { name: "evidenceNumber", title: t("evidence_number") },
  ];
  const classes = useStyles();
  const timeWorkersSuperiorArray = useAppSelector(
    selectTimeWorkersSuperiorArray
  );
  const timeWorkersSuperiorObject = useAppSelector(
    selectTimeWorkersSuperiorObject
  );
  const [selected, setSelected] = useState<any>("");
  const setAllWorkersLocal = useRef(setAllWorkers);

  const getAllWorkers = (): number[] =>
    timeWorkersSuperiorArray.map((el) => el.id);

  useEffect(() => {
    if (allWorkersSelected) {
      setSelectedWorkers(getAllWorkers());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (setAllWorkersLocal.current) {
      setAllWorkersLocal.current(timeWorkersSuperiorArray);
    }

    if (timeWorkersSuperiorArray.length === selectedWorkers.length) {
      setSelected(t("all"));
    } else if (selectedWorkers.length === 1) {
      let workerRow = timeWorkersSuperiorObject[selectedWorkers[0]];
      if (workerRow) {
        setSelected(workerRow.firstName + " " + workerRow.lastName);
      }
    } else if (selectedWorkers.length > 1) {
      setSelected(selectedWorkers.length);
    }

    if (timeWorkersSuperiorArray.length === selectedWorkers.length) {
      if (setAllWorkersSelected) {
        setAllWorkersSelected(true);
      }
    } else {
      if (setAllWorkersSelected) {
        setAllWorkersSelected(false);
      }
    }
  }, [
    timeWorkersSuperiorArray,
    selectedWorkers,
    timeWorkersSuperiorObject,
    setAllWorkersSelected,
    t,
  ]);

  const settings = useAppSelector(selectSettings);

  const accordionChange = useCallback(
    (event, accordion_expanded) => {
      if (setExpanded) {
        if (
          (!expanded && accordion_expanded) ||
          (expanded &&
            !accordion_expanded &&
            !isSearchbarFocused &&
            selectedWorkers.length > 0)
        ) {
          setExpanded(accordion_expanded);
        }
      }

      if (accordion_expanded && init) {
        setSelectedWorkers([]);
      }
    },
    [
      expanded,
      isSearchbarFocused,
      selectedWorkers.length,
      setSelectedWorkers,
      init,
    ]
  );

  const accordionClickOutside = useCallback(() => {
    if (autoCollapse && selectedWorkers.length > 0) {
      setExpanded(false);
    }
  }, [autoCollapse, selectedWorkers]);

  const lastSelectedSettingName = `lastSelectedWorkers_${authUser.currentProfile?.type}_${authUser.currentProfile?.subjectId}`;

  const handleSelectAllButtonClick = (e) => {
    e.stopPropagation();

    setSelectedWorkers(getAllWorkers());
    setInit(false);
  };

  const handleSelectNoneButtonClick = (e) => {
    e.stopPropagation();

    setSelectedWorkers([]);
    setInit(false);
  };

  const handleSelectLastButtonClick = (e) => {
    e.stopPropagation();

    if (settings[lastSelectedSettingName]?.value) {
      let workersIds = settings[lastSelectedSettingName]?.value
        .split(",")
        .map((el) => parseInt(el));

      let tempWorkersIds: any = [];
      let availableTimeWorkersIdsList = timeWorkersSuperiorArray.map(
        (el) => el.id
      );

      for (let i = 0; i < workersIds.length; i++) {
        if (availableTimeWorkersIdsList.includes(workersIds[i])) {
          tempWorkersIds.push(workersIds[i]);
        }
      }

      setSelectedWorkers(tempWorkersIds);
      setInit(false);
    }
  };

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    return row[columnName];
  }, []);

  return (
    <div>
      <ClickAwayListener onClickAway={accordionClickOutside}>
        <Accordion expanded={expanded} onChange={accordionChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading} component="div">
              <div className={styles.headerContainer}>
                <WorkerSearchBar
                  value={searchValue}
                  onFocus={() => setIsSearchBarFocused(true)}
                  onBlur={() => setIsSearchBarFocused(false)}
                  onChange={(e) => setSearchValue(e.target.value)}
                />

                <div
                  className={styles.headerButtonsContainer}
                  onClick={(e) => e.stopPropagation()}
                >
                  <ButtonStyled onClick={handleSelectLastButtonClick}>
                    {t("last2")}
                  </ButtonStyled>
                  {timeWorkersSuperiorArray.length ===
                  selectedWorkers.length ? (
                    <ButtonStyled
                      onClick={handleSelectNoneButtonClick}
                      color="#e11d48"
                      variant="outlined"
                    >
                      {t("cancel_selection")}
                    </ButtonStyled>
                  ) : (
                    <ButtonStyled onClick={handleSelectAllButtonClick}>
                      {t("all")}
                    </ButtonStyled>
                  )}
                  <WorkerPresetSelection
                    selectedWorkers={selectedWorkers}
                    setSelectedWorkers={(val) => {
                      setSelectedWorkers(val);
                      setInit(false);
                    }}
                  />
                </div>
                <div>
                  {`${t("selected_employees")}: ${selectedWorkers.length}`}
                  {selectedWorkers.length === 1 ? (
                    <>
                      <b>({selected})</b>
                    </>
                  ) : null}
                </div>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.root}>
            <TableComponent
              layoutSettingName={layoutSettingName}
              rows={timeWorkersSuperiorArray}
              columns={columns}
              ifEdit={false}
              ifAdd={false}
              ifDelete={false}
              selectData={(val) => {
                setSelectedWorkers(val);
                setInit(false);
              }}
              externalSearchValue={searchValue}
              setExternalSearchValue={setSearchValue}
              selectedData={selectedWorkers}
              pageSize={pageSize}
              customExcelCellValue={customExportCellValue}
              customPdfCellValue={customExportCellValue}
            />
          </AccordionDetails>
        </Accordion>
      </ClickAwayListener>
    </div>
  );
});

export default WorkerList;
