import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import AddEditVisualizationObjectPopup from "./Popups/AddEditVisualizationObjectPopup";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./VisualizationObjectsSMSAdministrator.module.scss";
import { useSMSVisualizationDataContext } from "../../../HelpersFunctions/SMSVisualizationDataProvider";
import SelectMenu from "../../helpersComponents/SelectMenu/selectMenu";
import useSMSOperations from "../../../hooks/sms/useSMSOperations";
import { SMSOperationType } from "../../../enums/SMS/SMSOperationType";

const VisualizationObjectsSMSAdministrator = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [modifiedData, setModifiedData] = useState<any[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [visualizationObjectIdToRemove, setVisualizationObjectIdToRemove] =
    useState(false);

  const { imagesData, imagesLoading } = useSMSVisualizationDataContext();
  const { triggerOperation } = useSMSOperations();

  const [imagesMap, setImagesMap] = useState<Map<number, ISMSCompleteImage>>(
    new Map()
  );

  useEffect(() => {
    if (!imagesLoading && imagesData && imagesData.length > 0) {
      const tempImagesMap = imagesData.reduce((acc, obj) => {
        acc.set(obj.id, obj);
        return acc;
      }, new Map());

      setImagesMap(tempImagesMap);
    }
  }, [imagesLoading, imagesData]);

  const columns = [
    {
      name: "file",
      title: t("icon"),
      customFormatter: (row) => {
        if (imagesMap && row.imageId && imagesMap.has(row.imageId)) {
          return (
            <img
              src={imagesMap.get(row.imageId)?.fileUrl}
              style={{ maxWidth: "100px" }}
              alt=""
            />
          );
        }

        return null;
      },
    },
    { name: "name", title: t("name") },
    { name: "description", title: t("description") },
    { name: "groups", title: t("group") },
    { name: "order", title: t("order") },

    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  const [
    ,
    fetchingStateVisualizationObjectsGroups,
    fetchAgainVisualizationObjectsGroups,
  ] = useFetchAndSetGET({
    path: `sms-administrator/visualization-objects-groups-library`,
    startFetchOnInitial: true,
  });

  const [, fetchingStateVisualizationObjects, fetchAgainVisualizationObjects] =
    useFetchAndSetGET({
      path: `sms-administrator/visualization-objects`,
      startFetchOnInitial: true,
    });

  const [, fetchAgainRemoveBuildingGroup] = useFetchOtherThanGET({
    path: `sms-administrator/visualization-objects/${visualizationObjectIdToRemove}`,
    method: "DELETE",
    body: visualizationObjectIdToRemove,
    setBody: setVisualizationObjectIdToRemove,
    successCallback: fetchAgainVisualizationObjects,
  });

  useEffect(() => {
    if (visualizationObjectIdToRemove) {
      fetchAgainRemoveBuildingGroup();
    }
  }, [visualizationObjectIdToRemove, fetchAgainRemoveBuildingGroup]);

  useEffect(() => {
    if (
      !fetchingStateVisualizationObjectsGroups.isFetching &&
      fetchingStateVisualizationObjectsGroups.response?.status === 200 &&
      !fetchingStateVisualizationObjects.isFetching &&
      fetchingStateVisualizationObjects.response?.status === 200
    ) {
      setDataLoaded(true);
    }
  }, [
    fetchingStateVisualizationObjectsGroups.isFetching,
    fetchingStateVisualizationObjectsGroups.response?.status,
    fetchingStateVisualizationObjects.isFetching,
    fetchingStateVisualizationObjects.response?.status,
  ]);

  useEffect(() => {
    if (dataLoaded) {
      let groupsMap = new Map();

      if (
        fetchingStateVisualizationObjectsGroups.response?.resJson &&
        fetchingStateVisualizationObjectsGroups.response?.resJson.length > 0
      ) {
        groupsMap =
          fetchingStateVisualizationObjectsGroups.response.resJson.reduce(
            (acc, obj) => {
              acc.set(obj.id, obj.name);
              return acc;
            },
            new Map()
          );
      }

      let tempResult = fetchingStateVisualizationObjects.response?.resJson;
      if (tempResult && tempResult.length > 0) {
        for (let i = 0; i < tempResult.length; i++) {
          let tempGroups: string | null = null;
          if (tempResult[i].groupsIds && tempResult[i].groupsIds.length > 0) {
            tempGroups = "";

            for (let j = 0; j < tempResult[i].groupsIds.length; j++) {
              if (j > 0) tempGroups += ",";
              tempGroups += groupsMap.get(tempResult[i].groupsIds[j]);
            }
          }

          tempResult[i].groups = tempGroups;
        }
      }
      setModifiedData(tempResult);
    }
  }, [
    dataLoaded,
    fetchingStateVisualizationObjectsGroups.response?.resJson,
    fetchingStateVisualizationObjects.response?.resJson,
  ]);

  const [copyVisualizationObjectBody, setCopyVisualizationObjectBody] =
    useState<any>(false);

  const [, fetchAgainCopyVisualizationObject] = useFetchOtherThanGET({
    path: "sms-administrator/copy-visualization-object",
    method: "POST",
    contentType: "application/json",
    body: copyVisualizationObjectBody,
    setBody: setCopyVisualizationObjectBody,
    successCallback: fetchAgainVisualizationObjects,
  });

  useEffect(() => {
    if (copyVisualizationObjectBody) {
      fetchAgainCopyVisualizationObject();
    }
  }, [copyVisualizationObjectBody, fetchAgainCopyVisualizationObject]);

  const contentMenuOptions: {
    optionName: React.ReactElement;
    onClick: () => void;
  }[] = [
    {
      optionName: <span>{t("import_from_file")}</span>,
      onClick: () => {
        triggerOperation(SMSOperationType.IMPORT_OBJECTS);
      },
    },
    {
      optionName: <span>{t("import_from_ac")}</span>,
      onClick: () => {
        triggerOperation(SMSOperationType.IMPORT_OBJECTS_FROM_AC);
      },
    },
    {
      optionName: <span>{t("export_to_file")}</span>,
      onClick: () => {
        triggerOperation(SMSOperationType.EXPORT_OBJECTS);
      },
    },
  ];

  return (
    <div>
      <TopBanner pathName={t("visualization_objects")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateVisualizationObjects.isFetching}
            isError={fetchingStateVisualizationObjects.isError}
            setIfFetchAgain={() => {
              fetchAgainVisualizationObjectsGroups();
              fetchAgainVisualizationObjects();
            }}
          >
            {!fetchingStateVisualizationObjects.isFetching && (
              <TableComponent
                layoutSettingName="layoutSMSAdministratorBuildingsGroups"
                rows={modifiedData}
                columns={columns}
                toolbarComponent={
                  <div className="container">
                    <SelectMenu
                      name={t("content")}
                      variant="outlined"
                      items={contentMenuOptions}
                    />
                  </div>
                }
                actionsColumnUpdated={{
                  popup: (props) => (
                    <AddEditVisualizationObjectPopup {...props} />
                  ),
                  successCallbackPopup: fetchAgainVisualizationObjects,
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t("confirm_delete_visualization_object")}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setVisualizationObjectIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                  customButton1: {
                    ifShow: true,
                    name: t("copy"),
                    customComponent: (row: INotificationInPortsGuest) => {
                      return (
                        <>
                          <Button>
                            <FontAwesomeIcon
                              icon={faCopy}
                              size="lg"
                              title={t("copy")}
                              style={{ padding: "5px" }}
                              onClick={() => {
                                setCopyVisualizationObjectBody(
                                  JSON.stringify({
                                    sourceObjectId: row.id,
                                  })
                                );
                              }}
                              className={styles.copyButton}
                            />
                          </Button>
                        </>
                      );
                    },
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default VisualizationObjectsSMSAdministrator;
