import React, { useState, useEffect, useCallback } from "react";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import { useTranslation } from "react-i18next";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import WorkerList from "../../../helpersComponents/WorkerList/workerList";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import Button from "../../../helpersComponents/Button/button";
import { CircularProgress } from "@material-ui/core";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import convertDateFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import useUpdateLastSelectedWorkers from "../../../../hooks/useUpdateLastSelectedWorkers/useUpdateLastSelectedWorkers";
import { DatePickerStyled } from "../../../helpersComponents/MaterialUi";

function MonthlyAbsencesSummary() {
  const { t } = useTranslation();

  const [selectedWorkers, setSelectedWorkers] = useState<any[]>([]);
  const [, setSelectedWorkersString] = useState<string | undefined>(undefined);
  const [, setAllWorkersSelected] = useState<boolean>(false);
  const [date, setDate] = useState(new Date());

  const [dataModified, setDataModified] = useState<null | any[]>(null);
  const [columns, setColumns] = useState<any>([]);

  const successCallback = useCallback(
    (responseData) => {
      let data = responseData.resJson;
      let tempRows: any = [];
      let columnsToAdd: any = [
        { name: "name", title: t("employee") },
        {
          name: "organizationalUnits",
          title: t("organizational_units"),
          customTooltip: t("organizational_units"),
        },
        {
          name: "evidenceNumber",
          title: t("evidence_number"),
          customTooltip: t("evidence_number"),
        },
        {
          name: "absenceTypeNameAbbrev",
          title: t("absence_shortcut"),
          customTooltip: t("absence_shortcut"),
        },
        {
          name: "used",
          title: t("used"),
          customTooltip: t("used"),
        },
        {
          name: "limit",
          title: t("limit"),
          customTooltip: t("limit"),
        },
        {
          name: "isLimitInDays",
          title: t("is_day"),
          customTooltip: t("is_day"),
        },
      ];

      data.forEach((dataItem, index) => {
        for (let i = 0; i < dataItem.absences.length; i++) {
          let tempRow = {
            id: index,
            name: `${dataItem.firstName} ${dataItem.lastName}`,
            organizationalUnits: dataItem.organizationalUnits,
            evidenceNumber: dataItem.evidenceNumber,
            absenceTypeNameAbbrev: dataItem.absences[i].absenceTypeNameAbbrev,
            used: dataItem.absences[i].isLimitInDays
              ? dataItem.absences[i].used / 60 / 8
              : dataItem.absences[i].used / 60,
            limit: dataItem.absences[i].limit,
            isLimitInDays: dataItem.absences[i].isLimitInDays
              ? t("yes")
              : t("no"),
          };

          tempRows.push(tempRow);
        }
      });

      setDataModified(tempRows);
      setColumns(columnsToAdd);
    },
    [t]
  );

  const [bodyRequest, setBodyRequest] = useState<false | string>(false);
  const [fetchingStatePostData, fetchAgainPostData] = useFetchOtherThanGET({
    path: `superior-time/monthly-absences-summary`,
    method: "POST",
    body: bodyRequest,
    contentType: "application/json",
    setBody: setBodyRequest,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: true,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainPostData();
    }
  }, [bodyRequest, fetchAgainPostData]);

  const layoutSettingName =
    "layoutSuperiorTimeMonthlyAbsencesSummarySelectWorkers";

  const [updateLastSelectedWorkers] =
    useUpdateLastSelectedWorkers(selectedWorkers);

  const confirmFilters = () => {
    let selectedWorkersLocal = [...selectedWorkers];
    let selectedWorkersStringLocal: string = "";
    selectedWorkersLocal.forEach((selectedId) => {
      selectedWorkersStringLocal =
        selectedWorkersStringLocal + selectedId.toString() + ",";
    });
    selectedWorkersStringLocal = selectedWorkersStringLocal.replace(
      /,\s*$/,
      ""
    );

    setSelectedWorkersString(selectedWorkersStringLocal);
    if (!selectedWorkersStringLocal) {
      return;
    }

    setDataModified(null);

    let queryObj = {
      dateFrom: convertDateFetch(getBeginningOfDate(date, "Month")),
      dateTo: convertDateFetch(getEndOfDate(date, "Month")),
      timeWorkersIds: selectedWorkersStringLocal.split(","),
    };

    setBodyRequest(JSON.stringify(queryObj));
    updateLastSelectedWorkers();
  };

  return (
    <div>
      <TopBanner pathName={t("monthly_absences_summary")} />
      <div className="flexAndCenter">
        <div>
          <div>
            <WorkerList
              layoutSettingName={layoutSettingName}
              setSelectedWorkers={setSelectedWorkers}
              selectedWorkers={selectedWorkers}
              pageSize={10}
              setAllWorkersSelected={setAllWorkersSelected}
            />
          </div>
        </div>
        <div className="selectDateDarkGlobal">
          <div className="selectYear dateContainer">
            <div>{t("select_month")}</div>
            <div>
              <DatePickerStyled
                view={["year", "month"]}
                format="yyyy-MM"
                date={date}
                setDate={setDate}
                darkTheme={true}
                width={"100%"}
              />
            </div>
            <div>
              {fetchingStatePostData.isFetching ? (
                <CircularProgress size={25} />
              ) : (
                <Button
                  onClick={() => {
                    confirmFilters();
                  }}
                >
                  {t("get_data")}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStatePostData.isFetching}
            isError={fetchingStatePostData.isError}
            setIfFetchAgain={() => {
              if (fetchingStatePostData.isError) {
                fetchAgainPostData();
              }
            }}
          >
            {!fetchingStatePostData.isFetching &&
              !fetchingStatePostData.isError &&
              dataModified !== null &&
              columns != null && (
                <TableComponent
                  layoutSettingName="layoutSuperiorTimeMonthlyAbsencesSummary"
                  rows={dataModified}
                  columns={columns}
                />
              )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
}

export default MonthlyAbsencesSummary;
