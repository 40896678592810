import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SMSVisualization.module.scss";
import MapArrangementToolbar from "./Toolbars/MapArrangmentToolbar/MapArrangementToolbar";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import MapArrangmentObjectsExplorer from "../../../helpersComponents/SMS/SMSVisualization/ObjectsExplorer/VisualizationObjectsExplorer";
import ButtonStyled from "../../../helpersComponents/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { TextField, Tooltip } from "@material-ui/core";
import { SMSVisualizationMode } from "../../../../enums/SMS/SMSVisualizationMode";
import SMSMapViewer from "./SMSMapViewer/SMSMapViewer";
import MapPreviewToolbar from "./Toolbars/MapPreviewToolbar/MapPreviewToolbar";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";
import { useMQTTClient } from "../../../../HelpersFunctions/MQTTClientProvider";
import {
  calculateVisualizationObjectState,
  calculateVisualizationObjectValue,
  getValuesNamesFromCondition,
} from "../../../../HelpersFunctions/sms/visualizationObjectsHelpers";
import { numberArraysHaveCommonElements } from "../../../../HelpersFunctions/arraysHelpers";
import { SMSVisualizationObjectBuiltInState } from "../../../../enums/SMS/SMSVisualizationObjectBuiltInState";
import { useSMSVisualizationDataContext } from "../../../../HelpersFunctions/SMSVisualizationDataProvider";
import {
  smsCompleteImageToBlob,
  smsCompleteMapToBlob,
} from "../../../../HelpersFunctions/imagesHelpers";

interface IProps {
  mode: SMSVisualizationMode;
  mapId?: number | null;
}

const SMSVisualization: React.FC<IProps> = ({ mode, mapId }) => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();

  const {
    buildingsGroupsData,
    buildingsData,
    visualizationObjectsGroupsData,
    visualizationObjectsData,
    visualizationObjectsLayersInstancesData,
    visualizationObjectsStatesGroupsData,

    visualizationDataLoading,
    visualizationDataError,
    //visualizationDataRefetch,
    visualizationObjectsLayersInstancesRefetch,
  } = useSMSVisualizationDataContext();

  const buildingsGroups = useMemo(
    () => buildingsGroupsData || [],
    [buildingsGroupsData]
  );

  const buildings = useMemo(() => buildingsData || [], [buildingsData]);

  const visualizationObjectsGroups = useMemo(
    () => visualizationObjectsGroupsData || [],
    [visualizationObjectsGroupsData]
  );

  const visualizationObjects = useMemo(
    () => visualizationObjectsData || [],
    [visualizationObjectsData]
  );

  const visualizationObjectsLayersInstances = useMemo(
    () => visualizationObjectsLayersInstancesData || [],
    [visualizationObjectsLayersInstancesData]
  );

  const visualizationObjectsStatesGroups = useMemo(
    () => visualizationObjectsStatesGroupsData || [],
    [visualizationObjectsStatesGroupsData]
  );

  const {
    mqttClientConnected,
    mqttSubscribe,
    mqttUnSubscribe,
    messagesRef,
    updateTrigger,
  } = useMQTTClient();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [mapObjects, setMapObjects] = useState<ISMSMapObject[]>([]);
  const [valuesSubscribedTopics, setValuesSubscribedTopics] = useState<
    string[]
  >([]);

  const [connectionInit, setConnectionInit] = useState(false);
  const [disconnectionCleanup, setDisconnectionCleanup] = useState(false);

  const [visualizationObjectsFilter, setVisualizationObjectsFilter] =
    useState("");

  const [selectedBuildingGroup, setSelectedBuildingGroup] =
    useState<ISMSBuildingsGroup | null>(null);
  const [selectedBuilding, setSelectedBuilding] = useState<ISMSBuilding | null>(
    null
  );

  const [objectsStatesMap, setObjectsStatesMap] = useState<Map<number, number>>(
    new Map()
  );

  const [objectsValuesMap, setObjectsValuesMap] = useState<Map<number, any>>(
    new Map()
  );

  const [selectedObjectsGroups, setSelectedObjectsGroups] = useState<number[]>(
    []
  );
  const [selectedStatesGroups, setSelectedStatesGroups] = useState<number[]>(
    []
  );

  const [selectedFloor, setSelectedFloor] = useState<ISMSBuildingFloor | null>(
    null
  );

  const [selectedMap, setSelectedMap] = useState<ISMSCompleteMap | undefined>(
    undefined
  );

  const [collapseAll, setCollapseAll] = useState(false);

  const {
    mapsData,
    mapsLoading,
    mapsError,
    mapsRefetch,
    imagesData,
    imagesLoading,
    imagesError,
    imagesRefetch,
    visualizationDataRefetch,
  } = useSMSVisualizationDataContext();

  useEffect(() => {
    if (mapsData && !mapsLoading) {
      if (selectedFloor) {
        setSelectedMap(mapsData.find((el) => el.id === selectedFloor.mapId));
      } else if (mapId) {
        setSelectedMap(mapsData.find((el) => el.id === mapId));
      }
    }
  }, [selectedFloor, mapsData, mapsLoading, mapId]);

  const [mapsUrlsMap, setMapsUrlsMap] = useState<Map<number, string>>(
    new Map()
  );

  const [imagesUrlsMap, setImagesUrlsMap] = useState<Map<number, string>>(
    new Map()
  );

  const [imagesInfo, setImagesInfo] = useState<Map<number, ISMSCompleteImage>>(
    new Map()
  );

  useEffect(() => {
    if (
      mqttClientConnected &&
      !connectionInit &&
      valuesSubscribedTopics &&
      valuesSubscribedTopics.length > 0
    ) {
      valuesSubscribedTopics.forEach((topic) => {
        mqttSubscribe({ topic, qos: 0, retain: true });
      });
      setConnectionInit(true);
    }
  }, [
    valuesSubscribedTopics,
    mqttClientConnected,
    mqttSubscribe,
    mqttUnSubscribe,
    connectionInit,
  ]);

  const initMapObjectsInstances = useCallback(() => {
    if (
      visualizationObjectsLayersInstances.length > 0 &&
      visualizationObjects.length > 0 &&
      selectedMap?.id
    ) {
      const filteredVisualizationObjectsLayersInstances =
        visualizationObjectsLayersInstances.filter(
          (el) => el.mapId === selectedMap?.id
        );

      let result: any[] = [];

      for (
        let i = 0;
        i < filteredVisualizationObjectsLayersInstances.length;
        i++
      ) {
        const layerId = filteredVisualizationObjectsLayersInstances[i].layerId;

        for (let j = 0; j < visualizationObjects.length; j++) {
          const obj = visualizationObjects[j];
          const foundLayer = obj.layers.find((el) => el.id === layerId);

          if (foundLayer) {
            let layerParameters = JSON.parse(foundLayer.parameters);
            let layerInstanceParameters: any = JSON.parse(
              filteredVisualizationObjectsLayersInstances[i].parameters
            );
            let layerToAdd: ISMSMapObject | null = null;

            switch (foundLayer.type) {
              case 0:
                const tempImageInfo: ISMSCompleteImage = imagesInfo.get(
                  foundLayer.imageId!
                )!;

                let tempImageLayer: ISMSMapImageObject = {
                  id: foundLayer.id,
                  instanceId: filteredVisualizationObjectsLayersInstances[i].id,
                  objectId: obj.id,
                  objectName: obj.name,
                  type: foundLayer.type,
                  name: foundLayer.name,
                  order: foundLayer.order,
                  groupsIds: obj.groupsIds,
                  visible: true,
                  actions: [],
                  imageUrl: foundLayer.imageId
                    ? imagesUrlsMap.get(foundLayer.imageId) || ""
                    : "",
                  statesIds: foundLayer.statesIds,
                  width: tempImageInfo.imageWidth,
                  height: tempImageInfo.imageHeight,
                  opacity: layerParameters.image_opacity,
                  rotationAngle: layerParameters.image_rotation_angle,
                  color: layerParameters.image_color,
                  baseScale: layerParameters.image_scale || 100,
                  scale: layerInstanceParameters.scale || 100,

                  position: {
                    x: layerInstanceParameters.position_x,
                    y: layerInstanceParameters.position_y,
                  },
                  draggable: layerInstanceParameters.draggable,
                  zoom: layerInstanceParameters.zoom,
                };

                obj.actions.forEach((action) => {
                  tempImageLayer.actions.push({
                    id: action.id,
                    name: action.name,
                    topic: action.topic,
                    value: action.value,
                    visible: false,
                    type: action.type,
                    urlAddress: action.urlAddress,
                    username: action.username,
                    password: action.password,
                    openURLInNewWindow: action.openURLInNewWindow,
                    statesIds: action.statesIds,
                  });
                });

                layerToAdd = tempImageLayer;
                break;

              case 1:
                break;

              case 2:
                let tempTextLayer: ISMSMapTextObject = {
                  id: foundLayer.id,
                  instanceId: filteredVisualizationObjectsLayersInstances[i].id,
                  objectId: obj.id,
                  objectName: obj.name,
                  type: foundLayer.type,
                  name: foundLayer.name,
                  order: foundLayer.order,
                  groupsIds: obj.groupsIds,
                  statesIds: foundLayer.statesIds,
                  visible: true,
                  actions: [],

                  text: layerParameters.text,
                  fontId: layerParameters.text_font,
                  color: layerParameters.text_color,
                  backgroundColor: layerParameters.text_background_color,
                  size: layerParameters.text_size,
                  opacity: layerParameters.text_background_color_opacity,

                  italics: layerParameters.text_options_italics,
                  bold: layerParameters.text_options_bold,
                  underline: layerParameters.text_options_underline,
                  baseScale: layerParameters.text_scale || 100,
                  scale: layerInstanceParameters.scale || 100,

                  position: {
                    x: layerInstanceParameters.position_x,
                    y: layerInstanceParameters.position_y,
                  },
                  draggable: layerInstanceParameters.draggable,
                  zoom: layerInstanceParameters.zoom,
                };

                obj.actions.forEach((action) => {
                  tempTextLayer.actions.push({
                    id: action.id,
                    name: action.name,
                    topic: action.topic,
                    value: action.value,
                    visible: false,
                    type: action.type,
                    urlAddress: action.urlAddress,
                    username: action.username,
                    password: action.password,
                    openURLInNewWindow: action.openURLInNewWindow,
                    statesIds: action.statesIds,
                  });
                });

                layerToAdd = tempTextLayer;
                break;
            }

            if (layerToAdd) {
              result.push(layerToAdd);
            }
          }
        }
      }

      setMapObjects(result);

      setObjectsStatesMap((prev) => {
        let tempMap = new Map(prev);
        let objectsIds = [...new Set(result.map((el) => el.objectId))];

        for (let i = 0; i < objectsIds.length; i++) {
          tempMap.set(
            objectsIds[i],
            SMSVisualizationObjectBuiltInState.OBJECT_UNAVAILABLE
          );
        }

        return tempMap;
      });
    }
  }, [
    imagesUrlsMap,
    imagesInfo,
    visualizationObjectsLayersInstances,
    visualizationObjects,
    selectedMap?.id,
  ]);

  useEffect(() => {
    if (visualizationDataLoading || visualizationDataError) return;

    setSelectedObjectsGroups(
      visualizationObjectsGroups.map((el) => el.id) || []
    );

    setSelectedStatesGroups(
      visualizationObjectsStatesGroups.map((el) => el.id) || []
    );

    initMapObjectsInstances();
  }, [
    initMapObjectsInstances,
    visualizationObjectsGroups,
    visualizationObjectsStatesGroups,
    visualizationDataLoading,
    visualizationDataError,
  ]);

  const successCallback = useCallback(() => {
    setIsEditMode(false);
    visualizationObjectsLayersInstancesRefetch();
  }, [visualizationObjectsLayersInstancesRefetch]);

  const [layersInstancesBodyRequest, setLayersInstancesBodyRequest] = useState<
    false | string
  >(false);
  const [, fetchAgainPostData] = useFetchOtherThanGET({
    path: `sms-administrator/visualization-objects-layers-instances`,
    method: "POST",
    body: layersInstancesBodyRequest,
    contentType: "application/json",
    setBody: setLayersInstancesBodyRequest,
    successCallback: successCallback,
  });

  useEffect(() => {
    if (layersInstancesBodyRequest) {
      fetchAgainPostData();
    }
  }, [layersInstancesBodyRequest, fetchAgainPostData]);

  useEffect(() => {
    if (!mapsLoading && mapsData && mapsData.length > 0) {
      let tempMap: Map<number, string> = new Map();

      mapsData?.forEach((item) => {
        tempMap.set(item.id, URL.createObjectURL(smsCompleteMapToBlob(item))); //TODO: Needs improvement
      });

      setMapsUrlsMap(tempMap);
    }
  }, [mapsData, mapsLoading]);

  useEffect(() => {
    if (!imagesLoading && imagesData && imagesData.length > 0) {
      let tempMap: Map<number, string> = new Map();
      let tempInfoMap: Map<number, ISMSCompleteImage> = new Map();

      imagesData.forEach((item) => {
        tempMap.set(item.id, URL.createObjectURL(smsCompleteImageToBlob(item))); //TODO: Needs improvement
      });

      imagesData.forEach((item) => {
        tempInfoMap.set(item.id, item);
      });

      setImagesUrlsMap(tempMap);
      setImagesInfo(tempInfoMap);
    }
  }, [imagesData, imagesLoading]);

  useEffect(() => {
    if (visualizationDataLoading || visualizationDataError) return;

    let tempTopics: string[] = [];
    let layersIds =
      visualizationObjectsLayersInstances
        .filter((el) => el.mapId === selectedMap?.id)
        .map((instance) => instance.layerId) || [];

    for (let i = 0; i < visualizationObjects.length; i++) {
      if (
        visualizationObjects[i].layers.find((el) => layersIds.includes(el.id))
      ) {
        visualizationObjects[i].values.forEach((value) => {
          if (!tempTopics.includes(value.topic)) {
            tempTopics.push(value.topic);
          }
        });
      }
    }

    setValuesSubscribedTopics(tempTopics);
  }, [
    visualizationObjects,
    visualizationObjectsLayersInstances,
    visualizationDataLoading,
    visualizationDataError,
    selectedMap?.id,
  ]);

  useEffect(() => {
    if (
      !mqttClientConnected &&
      !disconnectionCleanup &&
      mapObjects &&
      mapObjects.length > 0
    ) {
      setObjectsStatesMap((prev) => {
        let tempMap = new Map(prev);
        let objectsIds = [...new Set(mapObjects.map((el) => el.objectId))];

        for (let i = 0; i < objectsIds.length; i++) {
          tempMap.set(
            objectsIds[i],
            SMSVisualizationObjectBuiltInState.OBJECT_UNAVAILABLE
          );
        }

        return tempMap;
      });
      setDisconnectionCleanup(true);
      setConnectionInit(false);
    } else if (mqttClientConnected) {
      setDisconnectionCleanup(false);
    }
  }, [mqttClientConnected, mapObjects, disconnectionCleanup]);

  const initVisualization = useCallback(() => {
    if (selectedMap) {
      setMapObjects([]);
      setIsEditMode(false);
      visualizationObjectsLayersInstancesRefetch();
    }
  }, [selectedMap, visualizationObjectsLayersInstancesRefetch]);

  /*Inicjalizacja gdy wybrano mapę*/
  useEffect(() => {
    if (selectedMap && visualizationDataLoading) {
      initVisualization();
    }
  }, [selectedMap, initVisualization, visualizationDataLoading]);

  /*Czyścimy wszystko gdy brak wybranej mapy*/
  useEffect(() => {
    if (selectedFloor === null) {
      setSelectedMap(undefined);
      setMapObjects([]);
    }
  }, [selectedFloor]);

  const onSaveChangesClick = useCallback(() => {
    let tempBody: any = { mapId: selectedMap?.id, layersInstances: [] };

    for (let i = 0; i < mapObjects.length; i++) {
      switch (mapObjects[i].type) {
        case 0:
        case 2:
          let tempObj = mapObjects[i] as ISMSMapImageObject;
          let tempLayer = {
            id: tempObj.instanceId,
            layerId: tempObj.id,
            parameters: JSON.stringify({
              position_x: tempObj?.position?.x ?? 0,
              position_y: tempObj?.position?.y ?? 0,
              draggable: tempObj.draggable,
              zoom: tempObj.zoom,
              scale: tempObj.scale,
            }),
          };
          tempBody.layersInstances.push(tempLayer);
          break;
      }
    }

    setLayersInstancesBodyRequest(JSON.stringify(tempBody));
  }, [mapObjects, selectedMap?.id]);

  const onCancelChangesClick = useCallback(() => {
    initVisualization();
  }, [initVisualization]);

  const anyObjectLayersInstanceExists = useCallback(
    (id: number) => {
      let foundObject = visualizationObjects.find((el) => el.id === id);
      if (foundObject) {
        for (let i = 0; i < foundObject.layers.length; i++) {
          let foundLayer = mapObjects.find(
            (el) => el.id === foundObject.layers[i].id
          );
          if (foundLayer) {
            return true;
          }
        }
      }

      return false;
    },
    [mapObjects, visualizationObjects]
  );

  const layerInstanceExists = useCallback(
    (layerId: number) => {
      return mapObjects.some((el) => el.id === layerId);
    },
    [mapObjects]
  );

  const onMapObjectDrop = useCallback(
    (item: ISMSMapObjectDropItem) => {
      switch (item.type) {
        case "VISUALIZATION_OBJECT_ITEM":
          {
            let exists = anyObjectLayersInstanceExists(item.id);
            if (exists) {
              confirm({
                typeDialog: "info",
                text: `${t(
                  "some_visualization_object_layers_instances_already_exists_on_the_map"
                )}`,
                buttons: [{ buttonName: t("ok"), buttonId: 0 }],
              });
              return;
            }

            setIsEditMode(true);
            let objectId = item.id;

            if (visualizationObjects.length === 0) {
              return;
            }

            let foundObject = visualizationObjects.find(
              (el) => el.id === objectId
            );
            if (foundObject) {
              for (let i = 0; i < foundObject.layers.length; i++) {
                let foundLayer = foundObject.layers[i];
                let foundLayerParameters: any = JSON.parse(
                  foundLayer.parameters
                );

                let layerToAdd: ISMSMapObject | null = null;

                switch (foundLayer.type) {
                  case 0:
                    const tempImageInfo: ISMSCompleteImage = imagesInfo.get(
                      foundLayer.imageId!
                    )!;

                    let tempImageLayer: ISMSMapImageObject = {
                      id: foundLayer.id,
                      instanceId: 0,
                      objectId: objectId,
                      objectName: foundObject.name,
                      type: foundLayer.type,
                      name: foundLayer.name,
                      order: foundLayer.order,
                      groupsIds: foundObject.groupsIds,
                      visible: true,
                      actions: [],
                      statesIds: [],
                      imageUrl: foundLayer.imageId
                        ? imagesUrlsMap.get(foundLayer.imageId) || ""
                        : "",

                      width: tempImageInfo.imageWidth,
                      height: tempImageInfo.imageHeight,
                      opacity: foundLayerParameters.image_opacity,
                      rotationAngle: foundLayerParameters.image_rotation_angle,
                      color: foundLayerParameters.image_color,
                      baseScale: foundLayerParameters.image_scale || 100,
                      scale: 100,
                      position: {
                        x: item.positionX,
                        y: item.positionY,
                      },
                      draggable: true,
                      zoom: item.zoom,
                    };

                    foundObject.actions.forEach((action) => {
                      tempImageLayer.actions.push({
                        id: action.id,
                        name: action.name,
                        topic: action.topic,
                        value: action.value,
                        visible: false,
                        type: action.type,
                        urlAddress: action.urlAddress,
                        username: action.username,
                        password: action.password,
                        openURLInNewWindow: action.openURLInNewWindow,
                        statesIds: action.statesIds,
                      });
                    });

                    layerToAdd = tempImageLayer;
                    break;

                  case 1:
                    break;

                  case 2:
                    let tempTextLayer: ISMSMapTextObject = {
                      id: foundLayer.id,
                      instanceId: 0,
                      objectId: objectId,
                      objectName: foundObject.name,
                      type: foundLayer.type,
                      name: foundLayer.name,
                      order: foundLayer.order,
                      groupsIds: foundObject.groupsIds,
                      statesIds: foundLayer.statesIds,
                      visible: true,
                      actions: [],

                      text: foundLayerParameters.text,
                      fontId: foundLayerParameters.text_font,
                      color: foundLayerParameters.text_color,
                      backgroundColor:
                        foundLayerParameters.text_background_color,
                      size: foundLayerParameters.text_size,
                      opacity:
                        foundLayerParameters.text_background_color_opacity,

                      italics: foundLayerParameters.text_options_italics,
                      bold: foundLayerParameters.text_options_bold,
                      underline: foundLayerParameters.text_options_underline,

                      baseScale: foundLayerParameters.text_scale || 100,
                      scale: 100,

                      position: {
                        x: item.positionX,
                        y: item.positionY,
                      },
                      draggable: true,
                      zoom: item.zoom,
                    };

                    foundObject.actions.forEach((action) => {
                      tempTextLayer.actions.push({
                        id: action.id,
                        name: action.name,
                        topic: action.topic,
                        value: action.value,
                        visible: false,
                        type: action.type,
                        urlAddress: action.urlAddress,
                        username: action.username,
                        password: action.password,
                        openURLInNewWindow: action.openURLInNewWindow,
                        statesIds: action.statesIds,
                      });
                    });

                    layerToAdd = tempTextLayer;
                    break;
                }

                if (layerToAdd) {
                  setMapObjects((prev) => [...prev, layerToAdd]);
                }
              }
            }
          }
          break;

        case "VISUALIZATION_OBJECT_LAYER_ITEM":
          let exists = layerInstanceExists(item.id);
          if (exists) {
            confirm({
              typeDialog: "info",
              text: `${t(
                "visualization_object_layer_instance_already_exists_on_the_map"
              )}`,
              buttons: [{ buttonName: t("ok"), buttonId: 0 }],
            });
            return;
          }

          setIsEditMode(true);
          const layerId = item.id;

          if (visualizationObjects.length === 0) {
            return;
          }

          for (let i = 0; i < visualizationObjects.length; i++) {
            const obj = visualizationObjects[i];
            const foundLayer = obj.layers.find((el) => el.id === layerId);

            if (foundLayer) {
              let foundLayerParameters: any = JSON.parse(foundLayer.parameters);

              let layerToAdd: ISMSMapObject | null = null;

              switch (foundLayer.type) {
                case 0:
                  const tempImageInfo: ISMSCompleteImage = imagesInfo.get(
                    foundLayer.imageId!
                  )!;

                  let tempImageLayer: ISMSMapImageObject = {
                    id: foundLayer.id,
                    instanceId: 0,
                    objectId: obj.id,
                    objectName: obj.name,
                    type: foundLayer.type,
                    name: foundLayer.name,
                    order: foundLayer.order,
                    groupsIds: obj.groupsIds,
                    visible: true,
                    actions: [],
                    statesIds: [],
                    imageUrl: foundLayer.imageId
                      ? imagesUrlsMap.get(foundLayer.imageId) || ""
                      : "",

                    width: tempImageInfo.imageWidth,
                    height: tempImageInfo.imageHeight,
                    opacity: foundLayerParameters.image_opacity,
                    rotationAngle: foundLayerParameters.image_rotation_angle,
                    color: foundLayerParameters.image_color,

                    baseScale: foundLayerParameters.image_scale || 100,
                    scale: 100,

                    position: {
                      x: item.positionX,
                      y: item.positionY,
                    },
                    draggable: true,
                    zoom: item.zoom,
                  };

                  obj.actions.forEach((action) => {
                    tempImageLayer.actions.push({
                      id: action.id,
                      name: action.name,
                      topic: action.topic,
                      value: action.value,
                      visible: false,
                      type: action.type,
                      urlAddress: action.urlAddress,
                      username: action.username,
                      password: action.password,
                      openURLInNewWindow: action.openURLInNewWindow,
                      statesIds: action.statesIds,
                    });
                  });

                  layerToAdd = tempImageLayer;
                  break;

                case 1:
                  break;

                case 2:
                  let tempTextLayer: ISMSMapTextObject = {
                    id: foundLayer.id,
                    instanceId: 0,
                    objectId: obj.id,
                    objectName: obj.name,
                    type: foundLayer.type,
                    name: foundLayer.name,
                    order: foundLayer.order,
                    groupsIds: obj.groupsIds,
                    statesIds: foundLayer.statesIds,
                    visible: true,
                    actions: [],

                    text: foundLayerParameters.text,
                    fontId: foundLayerParameters.text_font,
                    color: foundLayerParameters.text_color,
                    backgroundColor: foundLayerParameters.text_background_color,
                    size: foundLayerParameters.text_size,
                    opacity: foundLayerParameters.text_background_color_opacity,

                    italics: foundLayerParameters.text_options_italics,
                    bold: foundLayerParameters.text_options_bold,
                    underline: foundLayerParameters.text_options_underline,

                    baseScale: foundLayerParameters.text_scale || 100,
                    scale: 100,

                    position: {
                      x: item.positionX,
                      y: item.positionY,
                    },
                    draggable: true,
                    zoom: item.zoom,
                  };

                  obj.actions.forEach((action) => {
                    tempTextLayer.actions.push({
                      id: action.id,
                      name: action.name,
                      topic: action.topic,
                      value: action.value,
                      visible: false,
                      type: action.type,
                      urlAddress: action.urlAddress,
                      username: action.username,
                      password: action.password,
                      openURLInNewWindow: action.openURLInNewWindow,
                      statesIds: action.statesIds,
                    });
                  });

                  layerToAdd = tempTextLayer;
                  break;
              }

              if (layerToAdd) {
                setMapObjects((prev) => [...prev, layerToAdd]);
              }
            }
          }
          break;
      }
    },
    [
      imagesUrlsMap,
      imagesInfo,
      visualizationObjects,
      layerInstanceExists,
      confirm,
      t,
      anyObjectLayersInstanceExists,
    ]
  );

  const onMapObjectDragEnd = useCallback((item: ISMSMapObjectDragEndItem) => {
    setMapObjects((prev) => {
      let tempMapObjects = prev.map((el) => {
        if (el.id === item.id) {
          return {
            ...el,
            position: { x: item.positionX, y: item.positionY },
          };
        } else {
          return el;
        }
      });

      return tempMapObjects;
    });
    setIsEditMode(true);
  }, []);

  const onMapObjectDelete = useCallback(
    (id: number, deleteObject: boolean = false) => {
      if (deleteObject) {
        setMapObjects((prev) =>
          prev.filter((mapObject) => mapObject.objectId !== id)
        );
      } else {
        setMapObjects((prev) =>
          prev.filter((mapObject) => mapObject.id !== id)
        );
      }
      setIsEditMode(true);
    },
    []
  );

  const onMapObjectToggleDraggable = useCallback((id: number) => {
    setMapObjects((prev) =>
      prev.map((el) => {
        if (el.id === id) {
          return { ...el, draggable: !el.draggable };
        } else {
          return el;
        }
      })
    );
    setIsEditMode(true);
  }, []);

  /*Visualization objects update state*/
  useEffect(() => {
    if (
      mode === SMSVisualizationMode.NORMAL &&
      visualizationObjects.length > 0 &&
      visualizationObjectsLayersInstances &&
      visualizationObjectsLayersInstances.length > 0
    ) {
      let newObjectsStatesMap = new Map<number, number>(); // key: object_id, value: state_id
      let newObjectsValuesMap = new Map<number, any>(); // key: value_id, value: value

      if (messagesRef.current.size === 0) return;

      let layersIds =
        visualizationObjectsLayersInstances
          ?.filter((el) => el.mapId === selectedMap?.id)
          ?.map((instance) => instance.layerId) || [];

      for (let i = 0; i < visualizationObjects.length; i++) {
        let obj = visualizationObjects[i];
        let foundLayer = visualizationObjects[i].layers.find((el) =>
          layersIds.includes(el.id)
        );

        if (foundLayer) {
          let allValuesExists = true;

          // Calculate values for visualization objects
          for (let j = 0; j < obj.values.length; j++) {
            let value = obj.values[j];
            let tempNewValue: any = undefined;

            if (messagesRef.current.has(value.topic)) {
              let mqttValue = messagesRef.current.get(value.topic);

              if (mqttValue !== undefined && mqttValue !== null) {
                let condition = value.condition;
                let property = value.property;

                tempNewValue = calculateVisualizationObjectValue(
                  JSON.parse(mqttValue),
                  condition,
                  property
                );
              } else {
                allValuesExists = false;
              }
            } else {
              allValuesExists = false;
            }

            newObjectsValuesMap.set(value.id, tempNewValue);
          }

          // Calculate states for visualization objects
          let valuesMap = new Map<string, number | string | boolean>();

          for (let j = 0; j < obj.values.length; j++) {
            let value = obj.values[j];
            if (newObjectsValuesMap.has(value.id)) {
              let mqttValue = newObjectsValuesMap.get(value.id);
              valuesMap.set(value.name, mqttValue);
            } else {
              valuesMap.set(value.name, false);
            }
          }

          // Clear all states
          newObjectsStatesMap.set(
            obj.id,
            SMSVisualizationObjectBuiltInState.OBJECT_UNAVAILABLE
          );

          // Calculate new states when all object values exists
          if (allValuesExists) {
            for (let j = 0; j < obj.states.length; j++) {
              let state = obj.states[j];

              let currentObjectState = calculateVisualizationObjectState(
                state.condition,
                valuesMap
              );

              if (currentObjectState) {
                newObjectsStatesMap.set(obj.id, state.id);
                break;
              }
            }
          }
        }
      }

      setObjectsStatesMap((prev) => {
        let tempMap = new Map(prev);
        newObjectsStatesMap.forEach((value, key) => {
          tempMap.set(key, value);
        });
        return tempMap;
      });

      setObjectsValuesMap((prev) => {
        let tempMap = new Map(prev);
        newObjectsValuesMap.forEach((value, key) => {
          tempMap.set(key, value);
        });
        return tempMap;
      });
    }
  }, [
    mode,
    visualizationObjects,
    visualizationObjectsLayersInstances,
    messagesRef,
    updateTrigger,
    selectedMap?.id,
  ]);

  /*
    Visualization objects visibility control
  */
  useEffect(() => {
    if (objectsStatesMap) {
      setMapObjects((prev) => {
        let tempMapObjects = [...prev];

        for (let i = 0; i < tempMapObjects.length; i++) {
          tempMapObjects[i].visible = false;

          for (let j = 0; j < tempMapObjects[i].actions.length; j++) {
            tempMapObjects[i].actions[j].visible = false;
          }

          let currentObjectState: ISMSVisualizationObjectState | undefined =
            undefined;

          let currentObjectStateId = objectsStatesMap.get(
            tempMapObjects[i].objectId
          );

          let foundObject = visualizationObjects.find(
            (el) => el.id === tempMapObjects[i].objectId
          );

          if (foundObject) {
            currentObjectState = foundObject.states.find(
              (el) => el.id === currentObjectStateId
            );
          }

          if (currentObjectStateId) {
            if (
              tempMapObjects[i].statesIds.includes(currentObjectStateId) &&
              (tempMapObjects[i].groupsIds.length === 0 ||
                numberArraysHaveCommonElements(
                  tempMapObjects[i].groupsIds,
                  selectedObjectsGroups
                )) &&
              (!currentObjectState?.groupId ||
                selectedStatesGroups.includes(currentObjectState.groupId))
            ) {
              tempMapObjects[i].visible = true;
            }

            for (let j = 0; j < tempMapObjects[i].actions.length; j++) {
              tempMapObjects[i].actions[j].visible =
                tempMapObjects[i].actions[j].statesIds.includes(
                  currentObjectStateId
                );
            }
          }
        }

        for (let i = 0; i < tempMapObjects.length; i++) {
          if (tempMapObjects[i].type === 2) {
            let textMapObject = tempMapObjects[i] as ISMSMapTextObject;

            let foundObject = visualizationObjects.find(
              (el) => el.id === tempMapObjects[i].objectId
            );

            let foundLayer: ISMSVisualizationObjectLayer | undefined =
              undefined;

            if (foundObject) {
              foundLayer = foundObject.layers.find(
                (el) => el.id === tempMapObjects[i].id
              );
            }

            if (foundObject && foundLayer) {
              let layerParameters = JSON.parse(foundLayer.parameters);
              let newLayerText: string = layerParameters.text ?? "";

              let allValuesNames = getValuesNamesFromCondition(newLayerText);

              if (allValuesNames && allValuesNames.length > 0) {
                for (let i = 0; i < allValuesNames.length; i++) {
                  let valueId = 0;

                  for (let j = 0; j < foundObject.values.length; j++) {
                    if (foundObject.values[j].name === allValuesNames[i]) {
                      valueId = foundObject.values[j].id;
                      break;
                    }
                  }

                  if (valueId > 0 && objectsValuesMap.has(valueId)) {
                    let tempValue: string = objectsValuesMap.get(valueId);

                    newLayerText = newLayerText.replace(
                      `$VALUES['${allValuesNames[i]}']`,
                      tempValue
                    );
                  }
                }

                textMapObject.text = newLayerText;
              }
            }
          }
        }

        return tempMapObjects;
      });
    }
  }, [
    objectsValuesMap,
    objectsStatesMap,
    selectedObjectsGroups,
    visualizationObjects,
    selectedStatesGroups,
  ]);

  return (
    <LoadingWrapper
      isLodadingProgress={
        visualizationDataLoading || mapsLoading || imagesLoading
      }
      isError={visualizationDataError || mapsError || imagesError}
      setIfFetchAgain={() => {
        if (visualizationDataError) {
          visualizationDataRefetch();
        }

        if (mapsError) {
          mapsRefetch();
        }

        if (imagesError) {
          imagesRefetch();
        }
      }}
      bigSize={true}
    >
      {mode === SMSVisualizationMode.NORMAL && (
        <div className={styles.mainContainer}>
          <MapPreviewToolbar
            objectsGroups={
              visualizationObjectsGroups.map((el) => {
                return { ...el, name: `${el.categoryName}->${el.name}` };
              }) || []
            }
            statesGroups={visualizationObjectsStatesGroups}
            selectedObjectsGroups={selectedObjectsGroups}
            setSelectedObjectsGroups={setSelectedObjectsGroups}
            selectedStatesGroups={selectedStatesGroups}
            setSelectedStatesGroups={setSelectedStatesGroups}
          />
          <div className={styles.mainContainerContent}>
            <div className={styles["main-content-column"]}>
              {!mapsLoading && !imagesLoading && selectedMap?.id && (
                <SMSMapViewer
                  mapId={selectedMap?.id}
                  mapUrl={
                    selectedMap?.id && mapsUrlsMap.has(selectedMap.id)
                      ? mapsUrlsMap.get(selectedMap.id) || ""
                      : ""
                  }
                  mapWidth={
                    selectedMap !== undefined
                      ? selectedMap.customWidth > 0
                        ? selectedMap.customWidth
                        : selectedMap.oryginalWidth
                      : 0
                  }
                  mapHeight={
                    selectedMap !== undefined
                      ? selectedMap.customHeight > 0
                        ? selectedMap.customHeight
                        : selectedMap.oryginalHeight
                      : 0
                  }
                  mapObjects={mapObjects}
                  setMapObjects={setMapObjects}
                  visualizationMode={mode}
                  onMapObjectDelete={onMapObjectDelete}
                  onMapObjectToggleDraggable={onMapObjectToggleDraggable}
                  buildings={buildings}
                  visualizationObjects={visualizationObjects}
                  visualizationObjectsLayersInstances={
                    visualizationObjectsLayersInstances
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}

      {mode === SMSVisualizationMode.ARRANGEMENT && (
        <div className={styles.mainContainer}>
          <MapArrangementToolbar
            buildingsGroups={buildingsGroups}
            buildings={buildings}
            selectedBuildingGroup={selectedBuildingGroup}
            setSelectedBuildingGroup={setSelectedBuildingGroup}
            selectedBuilding={selectedBuilding}
            setSelectedBuilding={setSelectedBuilding}
            selectedFloor={selectedFloor}
            setSelectedFloor={setSelectedFloor}
            isEditMode={isEditMode}
            onSaveChangesClick={onSaveChangesClick}
            onCancelChangesClick={onCancelChangesClick}
          />
          <div className={styles.mainContainerContent}>
            <div className={styles["main-content-column"]}>
              {!mapsLoading && !imagesLoading && selectedMap?.id ? (
                <SMSMapViewer
                  mapId={selectedMap?.id}
                  mapUrl={
                    selectedMap?.id && mapsUrlsMap.has(selectedMap.id)
                      ? mapsUrlsMap.get(selectedMap.id) || ""
                      : ""
                  }
                  mapObjects={mapObjects}
                  mapWidth={
                    selectedMap !== undefined
                      ? selectedMap.customWidth > 0
                        ? selectedMap.customWidth
                        : selectedMap.oryginalWidth
                      : 0
                  }
                  mapHeight={
                    selectedMap !== undefined
                      ? selectedMap.customHeight > 0
                        ? selectedMap.customHeight
                        : selectedMap.oryginalHeight
                      : 0
                  }
                  setMapObjects={setMapObjects}
                  onMapObjectDrop={onMapObjectDrop}
                  onMapObjectDragEnd={onMapObjectDragEnd}
                  visualizationMode={mode}
                  onMapObjectDelete={onMapObjectDelete}
                  onMapObjectToggleDraggable={onMapObjectToggleDraggable}
                  setIsEditMode={setIsEditMode}
                  buildings={buildings}
                  visualizationObjects={visualizationObjects}
                  visualizationObjectsLayersInstances={
                    visualizationObjectsLayersInstances
                  }
                />
              ) : (
                <div style={{ minHeight: "73vh" }}></div>
              )}
            </div>

            <div className={styles["menu-content-column"]}>
              <div>
                <div>
                  <Tooltip title={t("collapse_all")}>
                    <ButtonStyled
                      // variant="outlined"
                      style={{
                        borderRadius: "10px",
                        color: "inherit",
                        backgroundColor: "inherit",
                      }}
                      onClick={() => {
                        setCollapseAll(true);
                      }}
                      type="button"
                    >
                      <FontAwesomeIcon icon={faMinusSquare} />
                    </ButtonStyled>
                  </Tooltip>
                </div>
                <div className={styles["visualization-object-filter"]}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    style={{}}
                    size="small"
                    label={t("find_visualization_object")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setVisualizationObjectsFilter(e.target.value);
                    }}
                  />
                </div>
              </div>
              <MapArrangmentObjectsExplorer
                items={
                  visualizationObjects.filter(
                    (el) =>
                      !visualizationObjectsFilter.trim() ||
                      el.name
                        .toLowerCase()
                        .includes(
                          visualizationObjectsFilter.toLocaleLowerCase()
                        )
                  ) || []
                }
                collapseAll={collapseAll}
                setCollapseAll={setCollapseAll}
              />
            </div>
          </div>
        </div>
      )}
    </LoadingWrapper>
  );
};

export default SMSVisualization;
