import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  alpha,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ButtonBase from "@material-ui/core/ButtonBase";
import InputBase from "@material-ui/core/InputBase";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 100,
      fontSize: 13,
    },
    button: {
      fontSize: 13,
      display: "flex",
      padding: "4px 10px",
      gap: "8px",
      textWrap: "nowrap",
      width: "100%",
      justifyContent: "space-between",
      border: "1px solid #aaa",
      borderRadius: "999px",
      backgroundColor: "#dedede",
      transition:
        "background-color 0.1s ease-in-out, border-color 0.1s ease-in-out",

      "&:hover ": {
        backgroundColor: "#d5d5d5",
      },
      "&:active": {
        backgroundColor: "#d0d0d0",
      },
      "& svg": {
        width: 18,
        height: 18,
      },
    },
    buttonIfOpen: {
      backgroundColor: "#efefef",
      borderColor: "#777",
      "&:hover ": {
        backgroundColor: "#efefef",
      },
    },
    expandIcon: {
      transition: "transform 0.2s ease-in-out",
    },
    expandIconOnFocus: {
      transform: "rotate(180deg)",
    },
    tag: {
      marginTop: 3,
      height: 20,
      padding: ".15em 4px",
      textAlign: "center",
      fontWeight: 600,
      lineHeight: "15px",
      borderRadius: "9999px",
    },
    popper: {
      border: "1px solid rgba(27,31,35,.15)",
      boxShadow: "0 3px 12px rgba(27,31,35,.15)",
      borderRadius: "10px",
      overflow: "hidden",
      width: 300,
      marginTop: 3,
      zIndex: 1000,
      fontSize: 13,
      color: "#586069",
      backgroundColor: "#f6f8fa",
    },
    header: {
      borderBottom: "1px solid #e1e4e8",
      padding: "8px 10px",
      fontWeight: 600,
    },
    inputBase: {
      padding: 10,
      width: "100%",
      borderBottom: "1px solid #dfe2e5",
      "& input": {
        borderRadius: "10px",
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        border: "1px solid #ced4da",
        fontSize: 14,
        "&:focus": {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
          //outline: `2px solid aqua`,
        },
      },
    },
    paper: {
      boxShadow: "none",
      margin: 0,
      color: "#586069",
      fontSize: 13,
    },
    option: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    popperDisablePortal: {
      position: "relative",
    },
    iconSelected: {
      width: 17,
      height: 17,
      marginRight: 5,
      marginLeft: -2,
    },
    color: {
      width: 14,
      height: 14,
      flexShrink: 0,
      borderRadius: 3,
      marginRight: 8,
      marginTop: 2,
    },
    text: {
      flexGrow: 1,
    },
    close: {
      opacity: 0.6,
      width: 18,
      height: 18,
    },
  })
);

type PickerProps = {
  label: string;
  innerLabel?: string;
  items: MultiSelectItem[];
  selectedItems: number[];
  setSelectedItems: Dispatch<SetStateAction<number[]>>;
};
/**
 * A Material-UI button that opens a Popper with a list of labels. The user
 * can select/deselect labels by clicking on them. The selected labels are
 * displayed next to the button.
 *
 * @param {string} label - The label of the button.
 * @param {MultiSelectItem[]} items - The list of labels to select from.
 * @param {number[]} selectedItems - The currently selected labels.
 * @param {Dispatch<SetStateAction<number[]>>} setSelectedItems - The function
 *   to call when the user selects/deselects a label.
 */
export default function Picker(props: PickerProps) {
  const classes = useStyles();
  const anchorElRef = React.useRef<null | HTMLButtonElement>(null);
  const popperRef = React.useRef<null | HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const [pendingValue, setPendingValue] = React.useState<number[]>([]);

  const { t } = useTranslation();

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    props.setSelectedItems(pendingValue);
  };

  useEffect(() => {
    if (isOpen) {
      setPendingValue(props.selectedItems);
    }
  }, [isOpen, props.selectedItems]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen) return;
      if (
        anchorElRef.current &&
        !anchorElRef.current.contains(event.target as HTMLElement) &&
        !popperRef.current?.contains(event.target as HTMLElement)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  const id = props.label;

  return (
    <>
      <div className={classes.root}>
        <ButtonBase
          disableRipple
          className={`${classes.button} ${isOpen && classes.buttonIfOpen}`}
          aria-describedby={id}
          ref={anchorElRef}
          onClick={handleClick}
        >
          <span style={{ flexGrow: 1 }}>{props.label}</span>
          <span
            style={{
              color: `${
                props.selectedItems.length > 0 ? "#0c0" : "transparent"
              }`,
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            {props.selectedItems.length}/{props.items.length}
          </span>
          <ExpandMoreIcon
            className={`${classes.expandIcon} ${
              isOpen && classes.expandIconOnFocus
            }`}
          />
        </ButtonBase>
      </div>
      <Popper
        id={id}
        open={isOpen}
        ref={popperRef}
        anchorEl={anchorElRef.current}
        placement="bottom-start"
        className={classes.popper}
      >
        <div className={classes.header}>{props.innerLabel}</div>
        <Autocomplete
          open
          onClose={handleClose}
          multiple
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal,
          }}
          value={pendingValue.map((id) => props.items.find((l) => l.id === id))}
          onChange={(event, newValue) => {
            setPendingValue(newValue.map((l) => (l?.id ? l.id : 0)));
          }}
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          noOptionsText={t("none")}
          renderOption={(option, { selected }) => (
            <>
              {option && (
                <>
                  <DoneIcon
                    className={classes.iconSelected}
                    style={{ visibility: selected ? "visible" : "hidden" }}
                  />
                  <div className={classes.text}>{option.name}</div>
                  <CloseIcon
                    className={classes.close}
                    style={{ visibility: selected ? "visible" : "hidden" }}
                  />
                </>
              )}
            </>
          )}
          options={[...props.items].sort((a, b) => {
            // Display the selected labels first.
            let ai = props.selectedItems.indexOf(a.id);
            ai =
              ai === -1
                ? props.selectedItems.length + props.items.indexOf(a)
                : ai;
            let bi = props.selectedItems.indexOf(b.id);
            bi =
              bi === -1
                ? props.selectedItems.length + props.items.indexOf(b)
                : bi;
            return ai - bi;
          })}
          getOptionLabel={(option) => (option ? option.name : "")}
          renderInput={(params) => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.inputBase}
            />
          )}
        />
      </Popper>
    </>
  );
}
