import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../store/hooks";
import styles from "./SMSVisualizationEvents.module.scss";
import TableComponent from "../../../TableComponent/tableComponent";
import { selectEvents } from "../../../../../reducers/sms/visualizationData";
import useSMSFetchEvents from "../../../../../hooks/sms/useSMSFetchEvents/useSMSFetchEvents";
import { useCallback, useEffect, useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleDown,
  faCheck,
  faExclamationTriangle,
  faInfoCircle,
  faListAlt,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import { colorNameDictionary } from "../../../../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../../../../reducers/session";
import ApproveEventPopup from "./Popups/ApproveEventPopup/ApproveEventPopup";
import GoToVisualizationObjectPopup from "./Popups/GoToVisualizationObjectPopup/GoToVisualizationObjectPopup";
import { Button } from "@material-ui/core";
import { getEventPriorityName } from "../../../../../HelpersFunctions/sms/smsEventsHelpers";
import useSMSGoToVisualizationObject from "../../../../../hooks/sms/useSMSGoToVisualizationObject/useSMSGoToVisualizationObject";
import { createShortcut } from "../../../../../HelpersFunctions/misc/stringHelpers";

const Accordion = withStyles({
  rounded: {
    "&:first-child": {
      borderRadius: "10px",
    },
  },
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    borderRadius: "100px",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -2,
    height: "40px",
    padding: "2px 10px",
    "&$expanded": {
      minHeight: "48px",
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    overflowY: "hidden",
  },
}))(MuiAccordionDetails);

const SMSVisualizationEvents: React.FC<any> = () => {
  const { t } = useTranslation();
  const events = useAppSelector(selectEvents);
  const { fetchAgainEvents } = useSMSFetchEvents();
  const authUser = useAppSelector(selectAuthUser);
  const color = colorNameDictionary[authUser.currentProfile?.type];

  const {
    isDialogOpen: isVisualizationObjectsToGoDialogOpen,
    visualizationObjectsToGo,
    closeDialog,
    handleEvent,
    goToLocation,
  } = useSMSGoToVisualizationObject();

  const divRef = useRef<HTMLDivElement>(null);

  const [approveEventPopupParams, setApproveEventPopupParams] = useState<any>({
    isOpen: false,
    row: null,
  });

  useEffect(() => {
    fetchAgainEvents();
  }, [fetchAgainEvents]);

  const columns = [
    {
      name: "occurenceDate",
      title: t("occurence_date"),
      type: "date_seconds",
    },
    {
      name: "registrationDate",
      title: t("registration_date"),
      customTooltip: t("registration_date"),
      type: "date_seconds",
    },
    { name: "source", title: t("source") },
    { name: "type", title: t("type") },
    {
      name: "isAlarm",
      title: createShortcut(t("is_alarm")),
      customTooltip: t("is_alarm"),
    },
    {
      name: "priority",
      title: t("priority"),
    },
    { name: "description", title: t("description") },
    {
      name: "isApprovalRequired",
      title: createShortcut(t("is_approval_required")),
      customTooltip: t("is_approval_required"),
    },
    {
      name: "isApproved",
      title: createShortcut(t("is_approved")),
      customTooltip: t("is_approved"),
    },
    {
      name: "approver",
      title: createShortcut(t("approved_by2")),
      customTooltip: t("approved_by2"),
    },
    {
      name: "approvedAt",
      title: createShortcut(t("approved_date2")),
      customTooltip: t("approved_date2"),
      type: "date_seconds",
    },
  ];

  const formatEvents = (Events: ISMSEvent[]) =>
    Events.map(
      ({
        isAlarm: _isAlarm,
        priority: _priority,
        isApprovalRequired: _isApprovalRequired,
        isApproved: _isApproved,
        ...rest
      }) => {
        return {
          ...rest,
          isAlarm: _isAlarm ? t("yes") : t("no"),
          priority: getEventPriorityName(_priority, t),
          isApprovalRequired: _isApprovalRequired ? t("yes") : t("no"),
          isApproved: _isApproved ? t("yes") : t("no"),
        };
      }
    );

  const closeApproveEventDialog = useCallback(() => {
    setApproveEventPopupParams({
      isOpen: false,
      row: null,
    });
  }, []);

  const approveEventDialogSuccessCallback = useCallback(() => {
    fetchAgainEvents();
  }, [fetchAgainEvents]);

  const goToVisualizationObjectDialogSuccessCallback = useCallback(
    (objectToGo) => {
      goToLocation(objectToGo);
    },
    [goToLocation]
  );

  return (
    <>
      <Accordion defaultExpanded={false}>
        <AccordionSummary
          className={styles.accordionSummary}
          expandIcon={
            <FontAwesomeIcon icon={faArrowAltCircleDown} color={color} />
          }
        >
          <div className={styles.titleCotainer}>
            <p className={styles.title}>{t("events")}</p>
            <div>
              <FontAwesomeIcon icon={faListAlt} color="#6C757D" />{" "}
              {t("all_events")}: {events?.length ?? 0}
            </div>

            <div>
              <FontAwesomeIcon icon={faExclamationTriangle} color="#FF0000" />{" "}
              {t("alarm_events")}:{" "}
              {events?.filter((event) => event.isAlarm).length ?? 0}
            </div>
            <div>
              <FontAwesomeIcon icon={faInfoCircle} color="#007BFF" />{" "}
              {t("alert_events")}:{" "}
              {events?.filter((event) => !event.isAlarm).length ?? 0}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.eventsContainer}>
            <TableComponent
              layoutSettingName="layoutSMSUserEvents"
              ifHideWholeToolbalr={true}
              rows={formatEvents(events) || []}
              columns={columns}
              columnsWidth={[
                { columnName: "occurenceDate", width: "11em" },
                { columnName: "registrationDate", width: "11em" },
                { columnName: "approvedAt", width: "11em" },
                { columnName: "isAlarm", width: "5em" },
                { columnName: "type", width: "9em" },
                { columnName: "isApprovalRequired", width: "5em" },
                { columnName: "isApproved", width: "5em" },
                { columnName: "description", width: "23em" },
              ]}
              actionsColumnUpdated={{
                customButton1: {
                  ifShow: true,
                  name: t("approve"),
                  customComponent: (row) => {
                    return row.isApprovalRequired && !row.isApproved ? (
                      <Button
                        onClick={() => {
                          setApproveEventPopupParams({
                            isOpen: true,
                            row: row,
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          size="lg"
                          title={t("approve")}
                          style={{ color: "green" }}
                        />
                      </Button>
                    ) : (
                      <></>
                    );
                  },
                },
                customButton2: {
                  ifShow: true,
                  name: t("go_to_object"),
                  icon: (
                    <FontAwesomeIcon
                      icon={faLocationArrow}
                      size="lg"
                      title={t("go_to_object")}
                      style={{ color: "#0099ff" }}
                    />
                  ),
                  onClick: (row) => {
                    if (row) {
                      handleEvent(row);
                    }
                  },
                },
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <div ref={divRef}></div>

      <ApproveEventPopup
        isOpen={approveEventPopupParams.isOpen}
        row={approveEventPopupParams.row}
        closePopup={closeApproveEventDialog}
        successCallback={approveEventDialogSuccessCallback}
        container={divRef}
      />

      <GoToVisualizationObjectPopup
        isOpen={isVisualizationObjectsToGoDialogOpen}
        visualizationObjects={visualizationObjectsToGo}
        closePopup={closeDialog}
        successCallback={goToVisualizationObjectDialogSuccessCallback}
        container={divRef}
      />
    </>
  );
};

export default SMSVisualizationEvents;
