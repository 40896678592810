import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tooltip,
} from "@mui/material";
import { getOperationTypeName } from "../../../../../HelpersFunctions/sms/smsOperationsHelpers";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StatusChip from "./StatusChip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
//import DeleteIcon from "@mui/icons-material/Delete";

import { useTranslation } from "react-i18next";
import getReadableFileSizeString from "../../../../../HelpersFunctions/getReadableFileSizeString";
import { SMSOperationStatus } from "../../../../../enums/SMS/SMSOperationStatus";

import useFetchFile from "../../../../../hooks/fetchHooks/get/useFetchFile";
import { useEffect, useState } from "react";
import { SMSOperationType } from "../../../../../enums/SMS/SMSOperationType";

type SMSOperationsQueueTableProps = {
  operations: ISMSOperation[];
};
const SMSOperationsQueueTable = ({
  operations,
}: SMSOperationsQueueTableProps) => {
  const getBorderColor = (operationType: SMSOperationType) => {
    if (operationType > 200) {
      return "#e5e5ff";
    }
    if (operationType > 100) {
      return "inherit";
    }
    return "#ffe5e5";
  };

  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table sx={{ "& th, & td": { borderRight: "none", borderLeft: "none" } }}>
        <TableHead sx={{ "& th": { fontSize: "11px", fontWeight: 700 } }}>
          <TableRow>
            <TableCell>{t("Status")}</TableCell>
            <TableCell>{t("operation_type")}</TableCell>
            <TableCell>{t("file_name")}</TableCell>
            <TableCell>{t("file_size")}</TableCell>
            <TableCell>{t("created")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operations.map((operation) => (
            <TableRow
              key={operation.id}
              sx={{
                backgroundColor: getBorderColor(operation.operationType),
              }}
            >
              <TableCell
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "5px",
                }}
              >
                <StatusChip status={operation.operationStatus} />
                <Tooltip title={t("date_created")}>
                  <div
                    style={{
                      fontSize: "11px",
                      verticalAlign: "middle",
                      display: "flex",
                      alignItems: "center",
                      color: "#777",
                    }}
                  >
                    <CalendarMonthIcon
                      sx={{ fontSize: "15px", marginRight: "5px" }}
                    />
                    <span style={{ verticalAlign: "middle" }}>
                      {new Date(operation.createdAt).toLocaleString()}
                    </span>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell>
                {getOperationTypeName(operation.operationType, t)}
              </TableCell>
              <TableCell>{operation.fileName}</TableCell>
              <TableCell>
                {getReadableFileSizeString(operation.fileSize)}
              </TableCell>
              <TableCell>{operation.createdBy}</TableCell>
              <TableCell>
                {operation.operationStatus === SMSOperationStatus.COMPLETED &&
                  operation.fileName &&
                  operation.fileSize > 0 && (
                    <DownloadButtonExportedFile
                      operationId={operation.id}
                      fileName={operation.fileName}
                    />
                  )}
                {/* Place here delete button */}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DownloadButtonExportedFile = ({
  operationId,
  fileName,
}: {
  operationId: number;
  fileName: string;
}) => {
  const { t } = useTranslation();
  const [operationFileName, setOperationFileName] = useState<
    [false | string, string]
  >([false, ""]);

  const dataFile = useFetchFile(
    `sms-administrator/operations-queue-download-exported-file`,
    operationFileName
  );

  useEffect(() => {
    if (dataFile === null) return;
    setOperationFileName([false, ""]);
  }, [dataFile]);

  const handleDownload = () => {
    setOperationFileName([operationId.toString(), fileName]);
  };

  return (
    <Tooltip title={t("download")}>
      <Button
        sx={{
          padding: "2px",
          minWidth: "0",
        }}
        size="small"
        color="primary"
        onClick={() => handleDownload()}
      >
        <FileDownloadIcon />
      </Button>
    </Tooltip>
  );
};

export default SMSOperationsQueueTable;
