import { useDrag } from "react-dnd";
import styles from "./VisualizationObjectsExplorerObjectItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";
import VisualizationObjectsExplorerLayerItem from "./VisualizationObjectsExplorerLayerItem";

const VisualizationObjectsExplorerObjectItem: React.FC<
  IVisualizationObjectsExplorerObjectItem
> = ({
  id,
  name,
  description,
  icon,
  layers,
  onExpand,
  isExpanded,
  selectedLayer,
  setSelectedLayer,
}) => {
  const [, dragVisualizationObjectItem] = useDrag({
    type: "VISUALIZATION_OBJECT_ITEM",
    item: { id, type: "VISUALIZATION_OBJECT_ITEM" },
  });

  const btnExpandCollapseClick = () => {
    onExpand(id);
  };

  return (
    <div className={styles["visualization-object-main-container"]}>
      <div
        ref={dragVisualizationObjectItem}
        className={styles["visualization-object-item-container"]}
        onClick={btnExpandCollapseClick}
      >
        <div className={styles["visualization-object-item"]}>
          {icon ? (
            <img
              src={icon.fileUrl}
              alt=""
              style={{ maxWidth: "30px", maxHeight: "30px" }}
            />
          ) : (
            <img
              src="/images/object_icon_36x36.png"
              alt=""
              style={{ maxWidth: "30px", maxHeight: "30px" }}
            />
          )}
        </div>
        <Tooltip
          disableInteractive
          enterDelay={500}
          enterNextDelay={500}
          title={description || name}
        >
          <div className={styles["visualization-object-item-name"]}>{name}</div>
        </Tooltip>
        <div
          className={`${styles["visualization-object-item"]} ${
            styles["caret-icon"]
          } ${isExpanded && styles["caret-icon-rotated"]}`}
        >
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
      </div>

      <div
        className={` ${!isExpanded && styles["layers-container-collapsed"]} ${
          styles["layers-container"]
        }`}
      >
        {layers.map((layer) => (
          <VisualizationObjectsExplorerLayerItem
            key={layer.id}
            id={layer.id}
            name={layer.name}
            type={layer.type}
            groupItemsNumber={layer.groupItemsNumber}
            isSelected={selectedLayer === layer.id}
            setSelectedLayer={setSelectedLayer}
          />
        ))}
      </div>
    </div>
  );
};

export default VisualizationObjectsExplorerObjectItem;
