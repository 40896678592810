import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import AddEditBuildingGroupPopup from "./Popups/AddEditBuildingGroupPopup";
import useConfirm from "../../../hooks/useConfirm/useConfirm";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import SelectMenu from "../../helpersComponents/SelectMenu/selectMenu";
import { SMSOperationType } from "../../../enums/SMS/SMSOperationType";
import useSMSOperations from "../../../hooks/sms/useSMSOperations";

const BuildingsGroupsSMSAdministrator = () => {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [buildingGroupIdToRemove, setBuildingGroupIdToRemove] = useState(false);
  const { triggerOperation } = useSMSOperations();

  const columns = [
    { name: "name", title: t("name") },
    { name: "order", title: t("order") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  const [
    buildingsGroups,
    fetchingStateBuildingsGroups,
    fetchAgainBuildingsGroups,
  ] = useFetchAndSetGET({
    path: `sms-administrator/buildings-groups`,
    startFetchOnInitial: true,
  });

  const [, fetchAgainRemoveBuildingGroup] = useFetchOtherThanGET({
    path: `sms-administrator/buildings-groups/${buildingGroupIdToRemove}`,
    method: "DELETE",
    body: buildingGroupIdToRemove,
    setBody: setBuildingGroupIdToRemove,
    successCallback: fetchAgainBuildingsGroups,
  });

  useEffect(() => {
    if (buildingGroupIdToRemove) {
      fetchAgainRemoveBuildingGroup();
    }
  }, [buildingGroupIdToRemove, fetchAgainRemoveBuildingGroup]);

  const contentMenuOptions: {
    optionName: React.ReactElement;
    onClick: () => void;
  }[] = [
    {
      optionName: <span>{t("import_from_file")}</span>,
      onClick: () => {
        triggerOperation(SMSOperationType.IMPORT_BUILDINGS_GROUPS);
      },
    },
    {
      optionName: <span>{t("export_to_file")}</span>,
      onClick: () => {
        triggerOperation(SMSOperationType.EXPORT_BUILDINGS_GROUPS);
      },
    },
  ];

  return (
    <div>
      <TopBanner pathName={t("buildings_groups")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isLodadingProgress={fetchingStateBuildingsGroups.isFetching}
            isError={fetchingStateBuildingsGroups.isError}
            setIfFetchAgain={() => {
              fetchAgainBuildingsGroups();
            }}
          >
            {!fetchingStateBuildingsGroups.isFetching && (
              <TableComponent
                layoutSettingName="layoutSMSAdministratorBuildingsGroups"
                rows={buildingsGroups || []}
                columns={columns}
                toolbarComponent={
                  <div className="container">
                    <SelectMenu
                      name={t("content")}
                      variant="outlined"
                      items={contentMenuOptions}
                    />
                  </div>
                }
                actionsColumnUpdated={{
                  popup: (props) => <AddEditBuildingGroupPopup {...props} />,
                  successCallbackPopup: fetchAgainBuildingsGroups,
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t("confirm_delete_building_group")}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setBuildingGroupIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
};

export default BuildingsGroupsSMSAdministrator;
