export enum SMSOperationType {
  IMPORT_BACKUP = 1,
  IMPORT_BUILDINGS_GROUPS = 2,
  IMPORT_BUILDINGS = 3,
  IMPORT_OBJECTS = 4,
  IMPORT_IMAGES_LIBRARY = 5,
  IMPORT_OBJECTS_GROUPS_CATEGORIES_LIBRARY = 6,
  IMPORT_OBJECTS_GROUPS_LIBRARY = 7,
  IMPORT_OBJECTS_STATES_GROUPS_LIBRARY = 8,

  EXPORT_BACKUP = 101,
  EXPORT_BUILDINGS_GROUPS = 102,
  EXPORT_BUILDINGS = 103,
  EXPORT_OBJECTS = 104,
  EXPORT_IMAGES_LIBRARY = 105,
  EXPORT_OBJECTS_GROUPS_CATEGORIES_LIBRARY = 106,
  EXPORT_OBJECTS_GROUPS_LIBRARY = 107,
  EXPORT_OBJECTS_STATES_GROUPS_LIBRARY = 108,

  CLEAR_VISUALIZATION_DATA = 201,
  IMPORT_OBJECTS_FROM_AC = 202,
  DELETE_ALL_UNRELATED_IMAGES_FROM_IMAGES_LIBRARY = 203,
}
