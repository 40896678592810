import { useQuery } from "@tanstack/react-query";
import fetchClient from "../../../api/fetchClient";
import { ProfileType } from "../../../enums/profileType";
import { useAppSelector } from "../../../store/hooks";
import { selectAuthUser } from "../../../reducers/session";

const fetchSMSOperationsQueue = async (): Promise<ISMSOperation[]> =>
  fetchClient<ISMSOperation[]>("sms-administrator/operations-queue", {
    useAuth: true,
  });

const useFetchSMSOperationsQueue = () => {
  const authUser = useAppSelector(selectAuthUser);
  const profileType = authUser.currentProfile?.type;

  return useQuery<ISMSOperation[], Error>({
    queryKey: ["SMSOperationsQueue"],
    queryFn: fetchSMSOperationsQueue,
    enabled: profileType === ProfileType.SMS_ADMINISTRATOR,
    refetchInterval: 1000,
    staleTime: 500,
    cacheTime: 5 * 60 * 1000,
  });
};

export default useFetchSMSOperationsQueue;
