import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  saveMapToIndexedDB,
  loadAllMapsFromIndexedDB,
  deleteMapFromIndexedDB,
  isIndexedDBAvailable,
} from "../../HelpersFunctions/indexedDB/idb";
import { useAppSelector } from "../../store/hooks";
import { selectAuthUser, selectLanguage } from "../../reducers/session";
import { ProfileType } from "../../enums/profileType";
import { useEffect, useState } from "react";
import { getMimeType } from "../../HelpersFunctions/mimeTypes";

const fetchMapsLibraryFromAPI = async (
  url: string,
  lang: string,
  token: string
): Promise<ISMSMapsLibraryItem[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: "bearer " + token,
      "Accept-Language": lang,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

const fetchMapContentFromAPI = async (
  url: string,
  lang: string,
  token: string
): Promise<ArrayBuffer> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: "bearer " + token,
      "Accept-Language": lang,
    },
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.arrayBuffer();
};

const syncWithIndexedDB = async (
  mapsFromAPI: ISMSMapsLibraryItem[],
  fetchMapContentFromAPI: (id: number) => Promise<ArrayBuffer>
): Promise<ISMSCompleteMap[]> => {
  const mapsFromDB = await loadAllMapsFromIndexedDB();
  const mapsFromDBMap = new Map(mapsFromDB.map((map) => [map.id, map]));
  const updatedMaps: ISMSCompleteMap[] = [];

  for (const map of mapsFromAPI) {
    const dbMap = mapsFromDBMap.get(map.id);
    if (!dbMap || dbMap.fileContentHash !== map.fileContentHash) {
      const fileContent = await fetchMapContentFromAPI(map.id);
      const newMapDB: ISMSMapsLibraryItemDB = {
        id: map.id,
        fileContent,
        fileContentHash: map.fileContentHash,
      };
      await saveMapToIndexedDB(newMapDB);
      updatedMaps.push({ ...map, fileContent });
      mapsFromDBMap.delete(map.id);
    } else {
      updatedMaps.push({ ...map, fileContent: dbMap.fileContent });
    }
  }

  for (const [id] of mapsFromDBMap) {
    await deleteMapFromIndexedDB(id);
  }

  return updatedMaps;
};

const useSMSMapsLibrary = () => {
  const queryClient = useQueryClient();
  const authUser = useAppSelector(selectAuthUser);
  const language = useAppSelector(selectLanguage);
  const [initialData, setInitialData] = useState<ISMSCompleteMap[] | undefined>(
    undefined
  );

  const profileName =
    authUser.currentProfile?.type === ProfileType.SMS_ADMINISTRATOR
      ? "sms-administrator"
      : "sms-user";

  const downloadMapsLibraryUrl =
    window.globalConfig.API_URL + "/api/" + profileName + "/maps-library";
  const downloadMapBaseUrl =
    window.globalConfig.API_URL + "/api/" + profileName + "/download-map";

  useEffect(() => {
    if (
      authUser.currentProfile?.type !== ProfileType.SMS_ADMINISTRATOR &&
      authUser.currentProfile?.type !== ProfileType.SMS_USER
    )
      return;

    const loadInitialData = async () => {
      if (isIndexedDBAvailable()) {
        const dbData = await loadAllMapsFromIndexedDB();
        const apiData = await fetchMapsLibraryFromAPI(
          downloadMapsLibraryUrl,
          language,
          authUser.token
        );
        const completeData: ISMSMapsLibraryItem[] = apiData.map((map) => {
          const dbMap = dbData.find((dbMap) => dbMap.id === map.id);
          return {
            ...map,
            fileContent: dbMap?.fileContent,
          } as ISMSMapsLibraryItem;
        });
        setInitialData(completeData);
      } else {
        setInitialData([]);
      }
    };

    loadInitialData();
  }, [
    authUser.token,
    downloadMapsLibraryUrl,
    language,
    authUser.currentProfile?.type,
  ]);

  return useQuery<ISMSCompleteMap[], Error>(
    ["smsMapsLibrary"],
    () => {
      return fetchMapsLibraryFromAPI(
        downloadMapsLibraryUrl,
        language,
        authUser.token
      );
    },
    {
      initialData,
      onSuccess: async (data: any) => {
        if (isIndexedDBAvailable()) {
          const completeData = await syncWithIndexedDB(
            data,
            (id: number): Promise<ArrayBuffer> => {
              const url = downloadMapBaseUrl + `/${id}`;
              return fetchMapContentFromAPI(url, language, authUser.token);
            }
          );
          queryClient.setQueryData(
            ["smsMapsLibrary"],
            completeData.map((image) => {
              return {
                ...image,
                fileUrl: URL.createObjectURL(
                  new Blob([image.fileContent], {
                    type: getMimeType(image.fileName),
                  })
                ),
              };
            })
          );
        }
      },
      enabled:
        authUser.currentProfile?.type === ProfileType.SMS_ADMINISTRATOR ||
        authUser.currentProfile?.type === ProfileType.SMS_USER,
    }
  );
};

export default useSMSMapsLibrary;
