import TextFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import TwoColumnsRowsContainer from "../../../../helpersComponents/PopupHelpers/twoColumnsRowsContainer";
import Row from "../../../../helpersComponents/PopupHelpers/row";
import RowTitle from "../../../../helpersComponents/PopupHelpers/rowTitle";
import RowInput from "../../../../helpersComponents/PopupHelpers/rowInput";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchAndSetGET from "../../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import erro400getTranslatedErrorString from "../../../../../HelpersFunctions/erro400getTranslatedErrorString";
import useFetchOtherThanGET from "../../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import LoadingWrapper from "../../../../helpersComponents/LoadingWrapper/loadingWrapper";
import DatePickerFieldReactHookForm from "../../../../helpersComponents/ReactHookFormInputs/DatePickerField/DatePickerFieldReactHookForm";
import convertDateFetch from "../../../../../HelpersFunctions/dateAndTime/convertDateFetch";

const AddEditNotificationDailyLimitPopupContent: React.FC<any> = ({
  rowId,
  control,
  setValue,
  setErrorMessage,
  successCallback,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const [bodyRequest, setBodyRequest] = useState<any>(false);

  const [
    notificationsDailyLimit,
    fetchingStateNotificationsDailyLimit,
    fetchAgainNotificationsDailyLimit,
  ] = useFetchAndSetGET({
    path: `superior-time/notifications-daily-limits/${rowId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (rowId) {
      fetchAgainNotificationsDailyLimit();
    }
  }, [rowId, fetchAgainNotificationsDailyLimit]);

  const successCallbackAndClose = useCallback(() => {
    successCallback();
  }, [successCallback]);

  const errorCallback = useCallback(
    (response) => {
      if (response?.resJson?.errors) {
        let error = erro400getTranslatedErrorString(response.resJson.errors, t);
        setErrorMessage(error);
      }
    },
    [t, setErrorMessage]
  );

  const [, fetchAgainAddEditNotificationsDailyLimit] = useFetchOtherThanGET({
    path: `superior-time/notifications-daily-limits${rowId ? "/" + rowId : ""}`,
    method: rowId ? "PUT" : "POST",
    contentType: "application/json",
    body: bodyRequest,
    setBody: setBodyRequest,
    successCallback: successCallbackAndClose,
    errorCallback,
    disableErrorSnackbar: true,
  });

  useEffect(() => {
    if (bodyRequest) {
      fetchAgainAddEditNotificationsDailyLimit();
    }
  }, [bodyRequest, fetchAgainAddEditNotificationsDailyLimit]);

  const onSubmit = (data: any) => {
    let jsonDataToSend: any = {
      day: convertDateFetch(new Date(data.day)),
      limit: data.limit,
      reasonString: data.reasonString,
    };

    setBodyRequest(JSON.stringify(jsonDataToSend));
  };

  useEffect(() => {
    if (
      rowId &&
      !fetchingStateNotificationsDailyLimit.isFetching &&
      fetchingStateNotificationsDailyLimit.response?.status === 200
    ) {
      setValue("day", notificationsDailyLimit.day);
      setValue("limit", notificationsDailyLimit.limit);
      setValue("reasonString", notificationsDailyLimit.reasonString);
    }
  }, [
    rowId,
    setValue,
    fetchingStateNotificationsDailyLimit.isFetching,
    notificationsDailyLimit,
    fetchingStateNotificationsDailyLimit.response?.status,
  ]);

  return (
    <LoadingWrapper
      isLodadingProgress={fetchingStateNotificationsDailyLimit.isFetching}
      isError={fetchingStateNotificationsDailyLimit.isError}
      setIfFetchAgain={() => {
        if (fetchingStateNotificationsDailyLimit.isError) {
          fetchAgainNotificationsDailyLimit();
        }
      }}
    >
      <form
        key={"notification_daily_limit"}
        onSubmit={handleSubmit(onSubmit)}
        id="submitForm"
        noValidate
      >
        <div>
          {!rowId && (
            <TwoColumnsRowsContainer>
              <Row>
                <RowTitle width={"100px"}>{t("day")}:</RowTitle>
                <RowInput>
                  <DatePickerFieldReactHookForm
                    name={"day"}
                    control={control}
                    label={t("day")}
                    view={["year", "month", "date"]}
                    format="dd-MM-yyyy"
                    required={true}
                  />
                </RowInput>
              </Row>
            </TwoColumnsRowsContainer>
          )}

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("limit")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"limit"}
                  control={control}
                  label={t("limit")}
                  required={true}
                  type={"number"}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>

          <TwoColumnsRowsContainer>
            <Row>
              <RowTitle width={"100px"}>{t("reason")}:</RowTitle>
              <RowInput>
                <TextFieldReactHookForm
                  name={"reasonString"}
                  control={control}
                  label={t("reason")}
                  required={true}
                  type={"text"}
                />
              </RowInput>
            </Row>
          </TwoColumnsRowsContainer>
        </div>
      </form>
    </LoadingWrapper>
  );
};

export default AddEditNotificationDailyLimitPopupContent;
