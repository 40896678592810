import React, { Dispatch, SetStateAction } from "react";
import styles from "./MapPreviewToolbar.module.scss";
import Picker from "../Picker";
import { useTranslation } from "react-i18next";

interface IProps {
  objectsGroups: MultiSelectItem[];
  statesGroups: MultiSelectItem[];

  selectedObjectsGroups: number[];
  setSelectedObjectsGroups: Dispatch<SetStateAction<number[]>>;

  selectedStatesGroups: number[];
  setSelectedStatesGroups: Dispatch<SetStateAction<number[]>>;
}

const MapPreviewToolbar: React.FC<IProps> = ({
  objectsGroups,
  statesGroups,

  selectedObjectsGroups,
  setSelectedObjectsGroups,

  selectedStatesGroups,
  setSelectedStatesGroups,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.mainContainerToolbar}>
      <Picker
        label={t("object_filters")}
        innerLabel={t("choose_object_filters")}
        items={objectsGroups || []}
        selectedItems={selectedObjectsGroups}
        setSelectedItems={setSelectedObjectsGroups}
      />
      <Picker
        label={t("state_filters")}
        innerLabel={t("choose_state_filters")}
        items={statesGroups || []}
        selectedItems={selectedStatesGroups}
        setSelectedItems={setSelectedStatesGroups}
      />
    </div>
  );
};

export default MapPreviewToolbar;
