import React, { ReactElement } from "react";
import { Controller } from "react-hook-form";
import ErrorLabel from "../ErrorLabel/errorLabel";
import { FormControlLabel } from "@material-ui/core";
import RadioButtonStyled from "../../MaterialUi/RadioButton/radioButton";

export default function RadioButtonWithLabelFieldReactHookForm({
  name,
  value,
  label,
  showTopLabel,
  control,
  setValue,
  onChangeExtra,
  ...props
}: any): ReactElement {
  return (
    <div>
      {showTopLabel && (
        <div>
          <b>{label}:</b>
        </div>
      )}

      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value: fieldValue },
          fieldState: { error },
          formState,
        }) => {
          return (
            <>
              <FormControlLabel
                control={
                  <RadioButtonStyled
                    label={label}
                    checked={value === fieldValue}
                    //ifCircle={true}
                    {...props}
                    onChange={(event, value) => {
                      const selectedValue = Number(event.target.value); // Uzyskujemy wartość jako liczbę
                      onChange(selectedValue);
                      if (onChangeExtra) {
                        onChangeExtra(event, selectedValue);
                      }
                      setValue(name, selectedValue);
                    }}
                    value={value}
                  />
                }
                label={<span>{label}</span>}
              />

              {error?.message ? (
                <ErrorLabel errorName={error?.message} />
              ) : null}
            </>
          );
        }}
      />
    </div>
  );
}
