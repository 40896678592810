import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import TableAndActions from "./tableAndActions";
import { SnackbarStyled } from "../../../helpersComponents/MaterialUi";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { CircularProgress } from "@material-ui/core";
import Button from "../../../helpersComponents/Button/button";
import { INotification } from "./type";
import useRequestStatusName from "../../../../hooks/useRequestStatusName/useRequestStatusName";
import AddNotificationPopup from "../../../helpersComponents/Notifications/AddNotificationPopup";
import { arrivalTypes } from "../../../../Constants/arrivalTypes";
import moment from "moment";
import { useAppSelector } from "../../../../store/hooks";
import { selectSettings } from "../../../../reducers/settings";

function HolidaysRequestsContent() {
  const date = new Date();
  const { t } = useTranslation();
  const translateRequestStatusName = useRequestStatusName();
  const settings = useAppSelector(selectSettings);

  const [notificationsParams, setNotificationsParams] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59),
    showOnlyIntroduced: false,
  });

  const [snackbarState, setSnackbarState] = useState<any>([false, "error", ""]);

  const modifyResponseCallback = useCallback(
    (responseData: INotification[]) => {
      const modified = responseData?.map((row, index) => {
        return {
          ...row,
          guest: `${row.guestLastName} ${row.guestFirstName}`,
          requestStatus: translateRequestStatusName(row.requestStatus),
          arrivalDateString: new Date(row.arrivalDate).toLocaleString(
            t("scheduler_language"),
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }
          ),
          dateToString: row.dateTo
            ? new Date(row.dateTo).toLocaleString(t("scheduler_language"), {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
            : "-",
          isProvisioningString: row.isProvisioning ? t("yes") : t("no"),
          arrivalString: t(arrivalTypes[row.arrival]),
          createdAtString: new Date(row.createdAt).toLocaleString(
            t("scheduler_language"),
            {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }
          ),
          approvedAtString: row.approvedAt
            ? new Date(row.approvedAt).toLocaleString(t("scheduler_language"), {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })
            : null,
          meetingApprovalString: row.meetingApproval
            ? t("confirmed")
            : t("lack_of_confirmation"),
        };
      });
      return modified;
    },
    [translateRequestStatusName, t]
  );

  const [notifications, fetchingStateNotifications, fetchAgainNotifications] =
    useFetchAndSetGET<INotification[]>({
      path:
        "superior-time/notifications?dateFrom=" +
        computeDateRangeFetch(notificationsParams.startDate, "T00:00:00") +
        "&dateTo=" +
        computeDateRangeFetch(notificationsParams.endDate, "T23:59:59") +
        `&showOnlyIntroduced=${
          notificationsParams.showOnlyIntroduced ? "true" : "false"
        }`,
      modifyResponseCallback: modifyResponseCallback,
    });

  const getColumns = useCallback(() => {
    let tempColumns = [
      { name: "arrivalDate", title: t("date_of_arrival"), type: "date_hours" },
      { name: "guest", title: t("guest") },
      { name: "typeOfVisit", title: t("type_of_visit") },
      { name: "announces", title: t("notifying_employee") },
      { name: "announcesEmail", title: t("email") },
      { name: "conferenceRoomName", title: t("conference_room") },
    ];

    if (settings.WebAnonseBezParkingu?.value !== "1") {
      tempColumns.push({
        name: "parkingName",
        title: t("parking_lot"),
      });
    }

    tempColumns.push({ name: "requestStatus", title: t("status") });
    tempColumns.push({
      name: "createdAt",
      title: t("data_created"),
      type: "date_hours",
    });
    tempColumns.push({ name: "approverName", title: t("approved_by") });
    tempColumns.push({ name: "description", title: t("description") });

    if (settings.WebAnonseUkryjDateDoAwizacji?.value !== "1") {
      tempColumns.splice(1, 0, {
        name: "dateTo",
        title: t("date_date_to"),
        type: "date_hours",
      });
    }

    return tempColumns;
  }, [
    settings.WebAnonseUkryjDateDoAwizacji?.value,
    settings.WebAnonseBezParkingu?.value,
    t,
  ]);

  const columns = getColumns();

  const confirmFilters = (showOnlyIntroduced) => {
    setNotificationsParams((prev) => {
      return {
        ...prev,
        showOnlyIntroduced,
      };
    });
  };

  useEffect(() => {
    fetchAgainNotifications();
  }, [notificationsParams, fetchAgainNotifications]);

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStateNotifications.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <div>
            {" "}
            <Button
              onClick={() => {
                confirmFilters(false);
              }}
            >
              {t("refresh")}
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={() => {
                confirmFilters(true);
              }}
            >
              {t("show_introduced")}
            </Button>
          </div>
        )}
      </div>
    </>
  );

  const commitChanges = ({ added, changed, deleted }) => {
    fetchAgainNotifications();
  };

  const customExportCellValue = useCallback((row: any, columnName: string) => {
    if (columnName === "arrivalDate") {
      return row.arrivalDate
        ? moment(row.arrivalDate).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    if (columnName === "createdAt") {
      return row.createdAt
        ? moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")
        : "";
    }

    return row[columnName];
  }, []);

  return (
    <>
      <TopBanner pathName={t("list_of_notifications")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={notificationsParams.startDate}
            date2={notificationsParams.endDate}
            setDate={(value) => {
              setNotificationsParams((prev) => {
                return { ...prev, startDate: new Date(value) };
              });
            }}
            setDate2={(value) => {
              setNotificationsParams((prev) => {
                return { ...prev, endDate: new Date(value) };
              });
            }}
            darkTheme={true}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>

        {!fetchingStateNotifications.isFetching &&
          !fetchingStateNotifications.isError && (
            <TableAndActions
              rows={notifications}
              columns={columns}
              popup={AddNotificationPopup}
              ifAdd={true}
              commitChanges={commitChanges}
              parallerPatchPath={"superior-time/notifications"}
              settingsLayoutName={"layoutSuperiorTimeNotificationsList"}
              fetchObject={{
                fetchAgain: fetchAgainNotifications,
                isError: fetchingStateNotifications.isError,
                isFetching: fetchingStateNotifications.isFetching,
                response: fetchingStateNotifications.response,
                setIsFetching: () => {},
              }}
              customExcelCellValue={customExportCellValue}
              customPdfCellValue={customExportCellValue}
              dateFrom={notificationsParams.startDate}
              dateTo={notificationsParams.endDate}
            />
          )}
      </div>
      <SnackbarStyled
        setSnackbarState={setSnackbarState}
        snackbarState={snackbarState}
      />
    </>
  );
}

export default HolidaysRequestsContent;
