import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePickerRange from "../../../helpersComponents/DatePickerRange/datePickerRange";
import computeDateRangeFetch from "../../../../HelpersFunctions/dateAndTime/convertDateFetch";
import { TopBanner } from "../../../helpersComponents/TopBanner/topBanner";
import useFetchAndSetGET from "../../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { CircularProgress } from "@material-ui/core";
import Button from "../../../helpersComponents/Button/button";
import TableComponent from "../../../helpersComponents/TableComponent/tableComponent";
import getBeginningOfDate from "../../../../HelpersFunctions/dateAndTime/getBeginningOfDate";
import getEndOfDate from "../../../../HelpersFunctions/dateAndTime/getEndOfDate";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import RowDetailsNotificationsDailyLimit from "./RowDetailsNotificationsDailyLimit";
import AddEditNotificationDailyLimitPopup from "./Popups/AddEditNotificationDailyLimitPopup";
import useFetchOtherThanGET from "../../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useConfirm from "../../../../hooks/useConfirm/useConfirm";

function NotificationsDailyLimitsContent() {
  const date = new Date();
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const [
    notificationsDailyLimitIdToRemove,
    setNotificationsDailyLimitIdToRemove,
  ] = useState(false);

  const [notificationsDailyLimitsParams, setNotificationsDailyLimitsParams] =
    useState({
      startDate: getBeginningOfDate(date, "Day"),
      endDate: getEndOfDate(date, "Day"),
    });

  const [
    notificationsDailyLimits,
    fetchingStateNotificationsDailyLimits,
    fetchAgainNotificationsDailyLimits,
  ] = useFetchAndSetGET<INotificationDailyLimit[]>({
    path:
      "superior-time/notifications-daily-limits?dateFrom=" +
      computeDateRangeFetch(
        notificationsDailyLimitsParams.startDate,
        "T00:00:00"
      ) +
      "&dateTo=" +
      computeDateRangeFetch(
        notificationsDailyLimitsParams.endDate,
        "T23:59:59"
      ),
  });

  const columns = [
    { name: "day", title: t("day"), type: "date" },
    { name: "limit", title: t("limit") },
    { name: "reasonString", title: t("reason") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "editedBy", title: t("edited") },
    { name: "editedAt", title: t("data_edited"), type: "date_hours" },
  ];

  const confirmFilters = (showOnlyIntroduced) => {
    setNotificationsDailyLimitsParams((prev) => {
      return {
        ...prev,
        showOnlyIntroduced,
      };
    });
  };

  useEffect(() => {
    fetchAgainNotificationsDailyLimits();
  }, [notificationsDailyLimitsParams, fetchAgainNotificationsDailyLimits]);

  const confirmButtonRight = (
    <>
      <div className="getEmployeesButton">
        {fetchingStateNotificationsDailyLimits.isFetching ? (
          <CircularProgress size={25} />
        ) : (
          <div>
            <Button
              onClick={() => {
                confirmFilters(false);
              }}
            >
              {t("refresh")}
            </Button>
          </div>
        )}
      </div>
    </>
  );

  const [, fetchAgainRemoveNotificationsDailyLimit] = useFetchOtherThanGET({
    path: `superior-time/notifications-daily-limits/${notificationsDailyLimitIdToRemove}`,
    method: "DELETE",
    body: notificationsDailyLimitIdToRemove,
    setBody: setNotificationsDailyLimitIdToRemove,
    successCallback: fetchAgainNotificationsDailyLimits,
  });

  useEffect(() => {
    if (notificationsDailyLimitIdToRemove) {
      fetchAgainRemoveNotificationsDailyLimit();
    }
  }, [
    notificationsDailyLimitIdToRemove,
    fetchAgainRemoveNotificationsDailyLimit,
  ]);

  return (
    <>
      <TopBanner pathName={t("notifications_daily_limits")} />
      <div className="flexAndCenter">
        <div>
          <DatePickerRange
            view={["year", "month", "date"]}
            format="dd-MM-yyyy"
            date={notificationsDailyLimitsParams.startDate}
            date2={notificationsDailyLimitsParams.endDate}
            setDate={(value) => {
              setNotificationsDailyLimitsParams((prev) => {
                return { ...prev, startDate: new Date(value) };
              });
            }}
            setDate2={(value) => {
              setNotificationsDailyLimitsParams((prev) => {
                return { ...prev, endDate: new Date(value) };
              });
            }}
            darkTheme={true}
            confirmButtonRight={confirmButtonRight}
          ></DatePickerRange>
        </div>

        <div>
          <LoadingWrapper
            isLodadingProgress={
              fetchingStateNotificationsDailyLimits.isFetching
            }
            isError={fetchingStateNotificationsDailyLimits.isError}
            setIfFetchAgain={() => {
              fetchAgainNotificationsDailyLimits();
            }}
          >
            {!fetchingStateNotificationsDailyLimits.isFetching && (
              <TableComponent
                layoutSettingName="layoutSuperiorTimeAdministrationNotificationsDailyLimits"
                rows={notificationsDailyLimits || []}
                columns={columns}
                rowDetail={RowDetailsNotificationsDailyLimit}
                actionsColumnUpdated={{
                  popup: (props) => (
                    <AddEditNotificationDailyLimitPopup {...props} />
                  ),
                  successCallbackPopup: fetchAgainNotificationsDailyLimits,
                  addButton: {
                    ifShow: true,
                  },
                  removeButton: {
                    ifShow: true,
                    name: t("delete"),
                    onClick: async (item) => {
                      let selectedbuttonId = await confirm({
                        text: `${t("confirm_delete_notification_daily_limit")}`,
                        buttons: [
                          { buttonName: t("remove"), buttonId: 0 },
                          { buttonName: t("cancel"), buttonId: 1 },
                        ],
                      });

                      if ((await selectedbuttonId) === 0) {
                        setNotificationsDailyLimitIdToRemove(item.id);
                      }
                    },
                  },
                  editButton: {
                    ifShow: true,
                    name: t("edit"),
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>
    </>
  );
}

export default NotificationsDailyLimitsContent;
