import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
} from "@mui/material";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StatusChip from "./StatusChip";

import { SMSOperationStatus } from "../../../../../enums/SMS/SMSOperationStatus";

const operations: ISMSOperation[] = [
  {
    id: 1,
    operationType: 104,
    operationStatus: 0,
    fileName: "null",
    fileSize: 0,
    createdById: 56,
    createdBy: "serwis",
    createdAt: "2024-10-01T11:00:00.000Z" as unknown as Date,
  },
  {
    id: 2,
    operationType: 3,
    operationStatus: 1,
    fileName: "asd.zip",
    fileSize: 0,
    createdById: 56,
    createdBy: "sms_admin",
    createdAt: "2023-10-11T00:00:00.000Z" as unknown as Date,
  },
  {
    id: 3,
    operationType: 103,
    operationStatus: 2,
    fileName: "testasd-asd.json",
    fileSize: 0,
    createdById: 56,
    createdBy: "serwis",
    createdAt: "2022-11-21T00:00:00.000Z" as unknown as Date,
  },
  {
    id: 4,
    operationType: 101,
    operationStatus: 3,
    fileName: "test2.zip",
    fileSize: 0,
    createdById: 56,
    createdBy: "serwis",
    createdAt: "2022-11-21T13:32:12.000Z" as unknown as Date,
  },
  {
    id: 5,
    operationType: 1,
    operationStatus: 4,
    fileName: "test.zip",
    fileSize: 0,
    createdById: 56,
    createdBy: "serwis",
    createdAt: "2022-11-21T00:00:00.000Z" as unknown as Date,
  },
];

const SMSOperationsQueueTableSkeleton = () => {
  return (
    <TableContainer>
      <Table sx={{ "& th, & td": { borderRight: "none", borderLeft: "none" } }}>
        <TableHead sx={{ "& th": { fontSize: "11px", fontWeight: 700 } }}>
          <TableRow>
            <TableCell>
              <Skeleton>
                <span>Status</span>
              </Skeleton>
            </TableCell>
            <TableCell>
              <Skeleton>
                <span>Operation Type</span>
              </Skeleton>
            </TableCell>
            <TableCell>
              <Skeleton>
                <span>File Name</span>
              </Skeleton>
            </TableCell>
            <TableCell>
              <Skeleton>
                <span>File Name</span>
              </Skeleton>
            </TableCell>
            <TableCell>
              <Skeleton>
                <span>Created By</span>
              </Skeleton>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {operations.map((operation) => (
            <SkeletonTableBodyRow key={operation.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function SkeletonTableBodyRow() {
  return (
    <TableRow>
      <TableCell
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "5px",
        }}
      >
        <Skeleton>
          <StatusChip status={SMSOperationStatus.PENDING} />
        </Skeleton>
        <Skeleton>
          <div
            style={{
              fontSize: "11px",
              verticalAlign: "middle",
              display: "flex",
              alignItems: "center",
              color: "#777",
            }}
          >
            <CalendarMonthIcon sx={{ fontSize: "15px", marginRight: "5px" }} />
            <span style={{ verticalAlign: "middle" }}>
              {"---------------------"}
            </span>
          </div>{" "}
        </Skeleton>
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton />
      </TableCell>
      <TableCell>
        <Skeleton width={50} />
      </TableCell>
      <TableCell>
        <Skeleton width={100} />
      </TableCell>
      <TableCell>
        <Skeleton width={30} height={40} />
      </TableCell>
    </TableRow>
  );
}

export default SMSOperationsQueueTableSkeleton;
