import { colorNameDictionary } from "../../../ProfilesRouting/profilesDictionaries";
import { selectAuthUser } from "../../../../reducers/session";
import { useAppSelector } from "../../../../store/hooks";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Radio from "@mui/material/Radio";

export default function RadioButtonStyled(props) {
  const authUser = useAppSelector(selectAuthUser);
  const { ifCircle, overrideColor, isError, ifAnimation, ...restProps } = props;

  let color = !(
    Object.keys(authUser).length === 0 && authUser.constructor === Object
  )
    ? colorNameDictionary[authUser.currentProfile?.type]
    : "#1C84C6";

  return (
    <Radio
      style={{
        color: isError ? "red" : overrideColor ? overrideColor : color,
      }}
      icon={ifCircle ? <CircleUnchecked /> : undefined}
      checkedIcon={ifCircle ? <CircleCheckedFilled /> : undefined}
      {...restProps}
    />
  );
}
