import FormControlLabel from "@mui/material/FormControlLabel";
import styles from "./styles.module.scss";
import RadioButtonStyled from "../../../../MaterialUi/RadioButton/radioButton";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { HTMLControlOrientation } from "../../../../../../enums/HTMLControlOrientation";

const ElasticRadioButtonsGroup: React.FC<RadioButtonsGroupHTML> = ({
  label,
  name,
  isRequired,
  buttons,
  orientation,
  readonly,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
}) => {
  useEffect(() => {
    if (isRequired) {
      let anyButtonSelected = false;

      if (formValues[name]) {
        anyButtonSelected = true;
      }

      setFormErrors((prev) => {
        let objToRet = { ...prev };
        objToRet[name] = !anyButtonSelected;
        return objToRet;
      });
    }
  }, [formValues, isRequired, buttons, name, setFormErrors]);

  useEffect(() => {}, []);

  useEffect(() => {
    buttons.forEach((btn) => {
      if (btn.isSelected) {
        setFormValues((prev) => {
          let objToRet = { ...prev };
          objToRet[name] = btn.value;
          return objToRet;
        });
      }
    });
  }, [buttons, name, setFormValues]);

  return (
    <>
      <div>
        <Typography variant="body1" gutterBottom>
          {label}
          {isRequired ? <span style={{ color: "red" }}>*</span> : ""}:
        </Typography>

        <RadioGroup
          name={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFormValues((prev) => {
              let objToRet = { ...prev };
              objToRet[name] = e.target.value;
              return objToRet;
            });
          }}
        >
          <div
            className={
              orientation === HTMLControlOrientation.VERTICAL
                ? styles.formVerticalOrientation
                : styles.formHorizontalOrientation
            }
          >
            {buttons &&
              buttons.map((btn) => (
                <div>
                  <FormControlLabel
                    control={
                      <RadioButtonStyled
                        checked={formValues[name] === btn.value}
                        isError={formErrors[name] === true}
                        value={btn.value}
                        ifCircle={true}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        disabled={readonly}
                      />
                    }
                    label={btn.label}
                  />
                </div>
              ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
};

export default ElasticRadioButtonsGroup;
