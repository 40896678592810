import { useTranslation } from "react-i18next";
import RadioButtonWithLabelFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/RadioButtonWithLabelField/RadioButtonWithLabelField";
import TextFieldReactHookForm from "../../../helpersComponents/ReactHookFormInputs/TextField/textField";
import styles from "./ApproveRouletteEventPopup.module.scss";
import moment from "moment";
import LoadingWrapper from "../../../helpersComponents/LoadingWrapper/loadingWrapper";
import { RouletteEventOptionType } from "../../../../enums/RouletteEventOptionType";

const ApproveRouletteEventPopupContent: React.FC<any> = ({
  control,
  setValue,
  handleSubmit,
  onSubmit,
  rouletteAlertDetail,
  fetchingStateRouletteAlertDetail,
  fetchAgainRouletteAlertDetail,
}) => {
  const { t } = useTranslation();

  return (
    <LoadingWrapper
      isLodadingProgress={fetchingStateRouletteAlertDetail.isFetching}
      isError={fetchingStateRouletteAlertDetail.isError}
      setIfFetchAgain={() => {
        if (fetchingStateRouletteAlertDetail.isError) {
          fetchAgainRouletteAlertDetail();
        }
      }}
    >
      <form
        key={"approve_ommit_roulette_event_form"}
        onSubmit={handleSubmit(onSubmit)}
        id="submitForm"
        noValidate
      >
        <div>
          <div>
            <p className={styles.eventInfo}>
              {`${t("employee")}: ${
                rouletteAlertDetail?.employee ?? t("no_data")
              }`}
            </p>
            <p className={styles.eventInfo}>
              {rouletteAlertDetail?.eventTime
                ? moment(rouletteAlertDetail.eventTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : null}
            </p>
          </div>
          <div>
            <RadioButtonWithLabelFieldReactHookForm
              name={"selectedOption"}
              control={control}
              value={RouletteEventOptionType.IS_CHECKED_AND_NOT_STOPPED}
              setValue={setValue}
              label={t("is_checked_and_not_stopped")}
            />

            <RadioButtonWithLabelFieldReactHookForm
              name={"selectedOption"}
              control={control}
              value={RouletteEventOptionType.IS_CHECKED_AND_STOPPED}
              setValue={setValue}
              label={t("is_checked_and_stopped")}
            />

            <RadioButtonWithLabelFieldReactHookForm
              name={"selectedOption"}
              control={control}
              value={RouletteEventOptionType.EMPLOYEE_REFUSED}
              setValue={setValue}
              label={t("employe_refused")}
            />

            <TextFieldReactHookForm
              name="note"
              control={control}
              label={t("note")}
              rows={5}
              multiline
            />
          </div>
        </div>
      </form>
    </LoadingWrapper>
  );
};

export default ApproveRouletteEventPopupContent;
